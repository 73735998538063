import React, { useState } from 'react';
import './About.css';
import AlanTang from '../images/Alantang.jpg';
import YKNeo from '../images/YKNeo.jpg';
import meaningLogo from '../images/meaninglogo.jpg';
import brandmark from '../images/brandmark.jpg';
import brandmark1 from '../images/brandmark1.jpg';
import brandmark2 from '../images/brandmark2.jpg';
import visionMission from '../images/ourvisionourmission.jpg';
import coreValues from '../images/ourcore.jpg';
import about from '../images/about.png';
import { translations } from '../locales/translations';

function About({ language }) {
  const [expandedDirectors, setExpandedDirectors] = useState([]);
  const [isJourneyExpanded, setIsJourneyExpanded] = useState(false);

  const t = translations[language];

  const renderParagraph = (text) => {
    return text.split(/<blue>|<\/blue>/).map((part, index) => {
      if (index % 2 === 1) {
        // 奇数索引是需要显示为蓝色的文本
        return <span key={index} className="blue-text">{part}</span>;
      }
      return part;
    });
  };

  // 切换展开/收起状态
  const toggleDirectorInfo = (index) => {
    if (expandedDirectors.includes(index)) {
      setExpandedDirectors(expandedDirectors.filter(i => i !== index));
    } else {
      setExpandedDirectors([...expandedDirectors, index]);
    }
  };

  return (
    <div className="about-page">
      {/* Company History Section */}
      <section className="company-history-section">
        <div className="container">
          <h2 className="history-title">{t?.about?.companyHistory?.title}</h2>
          <div className="history-content">
            <div className={`history-paragraphs ${isJourneyExpanded ? 'expanded' : ''}`}>
              {t?.about?.companyHistory?.paragraphs.map((paragraph, index) => (
                <p key={index} className="history-paragraph">
                  {renderParagraph(paragraph)}
                </p>
              ))}
            </div>
            <button 
              className={`journey-read-more ${isJourneyExpanded ? 'expanded' : ''}`}
              onClick={() => setIsJourneyExpanded(!isJourneyExpanded)}
            >
              {isJourneyExpanded ? t?.about?.buttons?.collapse : t?.about?.buttons?.readMore}
            </button>
          </div>
        </div>
      </section>

      {/* Company Group Section */}
      <section className="company-group-section">
        <div className="container">
          <h2>{t?.about?.companyGroup?.title}</h2>
          <div className="company-list">
            {t?.about?.companyGroup?.companies.map((company, index) => (
              <div key={index} className="company-item">
                <h3>{company.name} <span className="company-reg">{company.reg}</span></h3>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Board of Directors Section */}
      <section className="board-section">
        <div className="container">
          <h2>{t?.about?.board?.title}</h2>
          <div className="directors-grid">
            {t?.about?.board?.directors
              .filter(director => !director.name.includes('Alan'))
              .map((director, index) => (
                <div key={index} className="director-card">
                  <img 
                    src={YKNeo}
                    alt={director.name} 
                    className="director-image" 
                  />
                  <div className={`director-info ${expandedDirectors.includes(index) ? 'expanded' : ''}`}>
                    <h3>{director.name}</h3>
                    <p className="director-title">{director.role}</p>
                    {director.desc.split('\n\n').map((paragraph, pIndex) => (
                      <p key={pIndex} className="director-desc">{paragraph}</p>
                    ))}
                  </div>
                  <button 
                    className={`read-more-btn ${expandedDirectors.includes(index) ? 'expanded' : ''}`}
                    onClick={() => toggleDirectorInfo(index)}
                  >
                    {expandedDirectors.includes(index) ? t?.about?.buttons?.collapse : t?.about?.buttons?.readMore}
                  </button>
                </div>
            ))}
          </div>
        </div>
      </section>

      {/* Board Members Section */}
      <section className="about-image-section">
        <div className="container">
          <h2 className="section-title">{t?.about?.teamInfo?.chartTitle}</h2>
          <img src={about} alt="About Us" className="about-full-image" />
        </div>
      </section>

      {/* Logo Section */}
      <section className="logo-section">
        <div className="container">
          <div className="logo-content">
            <div className="logo-image">
              <img src={meaningLogo} alt="Homedopt Logo Meaning" className="meaning-logo" />
            </div>
            <div className="logo-info">
              <h2>{t?.about?.logo?.meaning?.title}</h2>
              <p>{t?.about?.logo?.meaning?.desc1}</p>
              <p>{t?.about?.logo?.meaning?.desc2}</p>
            </div>
          </div>
          <div className="brandmark">
            <h3>{t?.about?.logo?.brandmark?.title}</h3>
            <img src={brandmark} alt="Brandmark Anatomy" className="brandmark-main" />
            <p>{t?.about?.logo?.brandmark?.desc}</p>
            <div className="brandmark-details">
              <img src={brandmark1} alt="Brandmark Detail 1" className="brandmark-detail" />
              <img src={brandmark2} alt="Brandmark Detail 2" className="brandmark-detail" />
            </div>
          </div>
        </div>
      </section>

      {/* Vision & Mission Section */}
      <section className="vision-mission">
        <div className="container">
          <img src={visionMission} alt="Vision and Mission" className="vision-mission-image" />
          <div className="vision-mission-content">
            <div className="vision">
              <h2>{t?.about?.vision?.title}</h2>
              <p>{t?.about?.vision?.desc}</p>
            </div>
            <div className="mission">
              <h2>{t?.about?.mission?.title}</h2>
              <p>{t?.about?.mission?.desc}</p>
            </div>
          </div>
        </div>
      </section>

      {/* Core Values Section */}
      <section className="core-values">
        <div className="container">
          <img src={coreValues} alt="Our Core Values" className="core-values-image" />
          <h2>{t?.about?.values?.title}</h2>
          <p className="values-intro">{t?.about?.values?.intro}</p>
          
          <div className="values-grid">
            <div className="value-item">
              <h3>{t?.about?.values?.care?.title}</h3>
              <p>{t?.about?.values?.care?.desc}</p>
            </div>
            <div className="value-item">
              <h3>{t?.about?.values?.right?.title}</h3>
              <p>{t?.about?.values?.right?.desc}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About; 