import React from 'react';
import './Home.css';
import family from '../images/family.png';
import sharehand from '../images/sharehand.jpg';
import bigimage from '../images/bigimage.png';
import story1 from '../images/1.jpg';
import story2 from '../images/2.jpg';
import story3 from '../images/3.jpg';
import { translations } from '../locales/translations';
import { Link } from 'react-router-dom';

function Home({ language }) {
  const t = translations[language];

  const stats = [
    { number: "7+", label: t?.stats?.experience || "Years Experience" },
    { number: "250+", label: t?.stats?.adoptions || "Homes Adopted" },
    { number: "200+", label: t?.stats?.saved || "Homes Saved" }
  ];

  const testimonials = [
    {
      content: t?.testimonials?.stories[0]?.content,
      author: t?.testimonials?.stories[0]?.author || "MR KHOO",
      location: t?.testimonials?.stories[0]?.location || "TAMAN MUHIBBAH, KAPAR, SELANGOR"
    },
    {
      content: t?.testimonials?.stories[1]?.content,
      author: t?.testimonials?.stories[1]?.author || "KAK ROS",
      location: t?.testimonials?.stories[1]?.location || "TAMAN BUKIT AMPANG"
    },
    {
      content: t?.testimonials?.stories[2]?.content,
      author: t?.testimonials?.stories[2]?.author || "CIK LIZA",
      location: t?.testimonials?.stories[2]?.location || "TAMAN DESA JAYA, KEPONG, KL"
    }
  ];

  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-container">
          <div className="hero-content">
            <div className="hero-badges">
              <div className="hero-badge">
                <span className="badge-icon">🏆</span>
                {t?.hero?.badge || "Malaysia's First Home Adoption Platform"}
              </div>
              <div className="certification-badge">
                <span className="cert-icon">✓</span>
                {t?.hero?.certification || "Government Certified Institution"}
              </div>
            </div>
            <h1 className="hero-title">
              <span className="title-highlight">{t?.hero?.title || "Professional Protection"}</span>
              <br />{t?.hero?.subtitle || "For Your Dream Home"}
            </h1>
            <p className="hero-subtitle">
              {t?.hero?.description || "Professional Property Protection Plan · Flexible Payment Plan · 7 Years Experience"}
            </p>
            <div className="hero-buttons">
              <Link to="/contact" className="primary-button">
                <span className="button-icon">💬</span>
                {t?.hero?.buttons?.consult}
              </Link>
              <Link to="/homeadoption" className="secondary-button">
                <span className="button-icon">📋</span>
                {t?.hero?.buttons?.more}
              </Link>
            </div>
          </div>
          <div className="hero-visual">
            <div className="image-frame">
              <div className="image-background"></div>
              <img src={family} alt="幸福家庭" className="hero-image" />
              <div className="image-decoration"></div>
            </div>
          </div>
        </div>
        <div className="hero-pattern">
          <div className="pattern-overlay"></div>
          <div className="gradient-overlay"></div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="stats-section">
        <div className="stats-container">
          <h2 className="stats-title">{t?.values?.proven || "Proven"}<br/>{t?.values?.transparent || "Transparent and Trustworthy"}</h2>
          <div className="stats-grid">
            {stats.map((stat, index) => (
              <div key={index} className="stat-card">
                <span className="stat-number">{stat.number}</span>
                <span className="stat-label">{stat.label}</span>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="services-section">
        <div className="services-container">
          <h2 className="services-title">{t?.services?.title || "我们的服务"}</h2>
          <p className="services-subtitle">{t?.services?.subtitle || "专业的房产保障解决方案"}</p>
          <div className="services-grid">
            {t?.services?.items.map((service, index) => (
              <div key={index} className="service-card">
                <div className="service-header">
                  <span className="service-number">{`0${index + 1}`}</span>
                  <div className="service-icon">{service.icon}</div>
                </div>
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">{service.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="about-section">
        <div className="about-container">
          <div className="about-header">
            <div className="about-badge-wrapper">
              <div className="about-badge-highlight">{t?.about?.badge}</div>
            </div>
            <h2 className="about-title">{t?.about?.title}</h2>
            <p className="about-description">{t?.about?.description}</p>
          </div>
          <div className="about-cards">
            {t?.about?.features.map((feature, index) => (
              <div key={index} className="feature-card">
                <div className="feature-icon">{["✨", "📊", "💡"][index]}</div>
                <div className="feature-content">
                  <h3 className="feature-title">{feature.title}</h3>
                  <p className="feature-text">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <img src={sharehand} alt={t?.about?.title} className="about-bg-image" />
      </section>

      {/* Values Section */}
      <section className="values-section-green">
        <div className="values-container">
          <div className="values-header">
            <div className="section-badge">{t?.values?.title || "Our Values"}</div>
            <h2>{t?.values?.subtitle || "Professional Service for You"}</h2>
          </div>
          
          <div className="values-content">
            <div className="values-main">
              <div className="main-text">
                <h3>{t?.values?.forOwners?.title || "For Property Owners"}</h3>
                <p>{t?.values?.forOwners?.description}</p>
              </div>
              <div className="main-text">
                <h3>{t?.values?.forClients?.title || "For Our Clients"}</h3>
                <p>{t?.values?.forClients?.description}</p>
              </div>
            </div>

            <div className="values-features">
              <div className="features-image">
                <img src={bigimage} alt="房屋领养计划" />
                <div className="image-overlay"></div>
              </div>
              <div className="features-list">
                {t?.values?.principles?.map((principle, index) => (
                  <div key={index} className="feature-item">
                    <div className="feature-content">
                      <h4>{principle.title}</h4>
                      <p>{principle.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials-section">
        <div className="section-header">
          <h2>{t?.testimonials?.title || "Original Homeowner Stories"}</h2>
          <p>{t?.testimonials?.subtitle || "Real Client Experiences"}</p>
        </div>
        <div className="testimonials-slider">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <div className="testimonial-image">
                <img src={[story1, story2, story3][index]} alt={testimonial.author} />
              </div>
              <p>{testimonial.content}</p>
              <div className="testimonial-author">{testimonial.author}</div>
              <div className="testimonial-location">{testimonial.location}</div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Home; 