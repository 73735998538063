import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './UserManagement.css';
import { translations } from '../locales/translations';
import { useUser } from '../context/UserContext';

// 将 roleWeights 移到组件外部作为常量
const ROLE_WEIGHTS = {
    'admin': 1,
    'boss': 2,
    'manager': 3,
    'leader': 4,
    'agent': 5
};

// 排序类型常量
const SORT_TYPES = {
    ROLE: 'role',
    TIME_ASC: 'time_asc',
    TIME_DESC: 'time_desc'
};

function UserManagement({ language = 'en' }) {
    const { user: currentUser } = useUser();
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [editingUser, setEditingUser] = useState({
        id: null,
        name: '',
        ic: '',
        accountNo: '',
        email: '',
        role: 'agent',
        uplineId: null,
        password: ''
    });
    const [uplineUsers, setUplineUsers] = useState([]);
    const t = translations[language]?.userManagement;
    const [sortType, setSortType] = useState(SORT_TYPES.ROLE);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [uplineSearch, setUplineSearch] = useState('');
    const [filteredUplines, setFilteredUplines] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (!currentUser || currentUser.role !== 'admin') {
            navigate('/');
        }
    }, [currentUser, navigate]);

    const fetchUsers = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(
                'https://homedoptwebsite-1.onrender.com/api/users',
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            let sortedUsers = [...response.data.users];
            
            // 先按角色排序
            sortedUsers.sort((a, b) => ROLE_WEIGHTS[a.role] - ROLE_WEIGHTS[b.role]);
            
            // 相同角色内按时间排序
            const sameRoleUsers = {};
            Object.keys(ROLE_WEIGHTS).forEach(role => {
                const usersInRole = sortedUsers.filter(user => user.role === role);
                // 根据当前排序类型决定排序方式
                usersInRole.sort((a, b) => {
                    if (sortType === SORT_TYPES.TIME_ASC) {
                        return new Date(a.createdAt) - new Date(b.createdAt);
                    } else {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    }
                });
                sameRoleUsers[role] = usersInRole;
            });
            
            // 重新组合排序后的用户
            sortedUsers = Object.keys(ROLE_WEIGHTS).flatMap(role => sameRoleUsers[role]);
            
            setUsers(sortedUsers);
            setUplineUsers(sortedUsers);
        } catch (err) {
            setError(t?.errors?.fetchError || '获取用户列表失败');
        } finally {
            setLoading(false);
        }
    }, [t?.errors?.fetchError, sortType]);

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers]);

    const handleEditUser = (user) => {
        setEditingUser({
            ...user,
            password: '',  // 清空密码字段
            confirmPassword: ''  // 添加确认密码字段
        });
    };

    const handleAddUser = () => {
        setEditingUser({
            id: null,
            name: '',
            email: '',
            password: '',
            role: 'agent',
            uplineId: null,
            ic: '',
            accountNo: ''
        });
    };

    const handleDeleteUser = async (userId) => {
        if (!window.confirm(t?.messages?.confirmDelete || '确定要删除此用户吗？')) {
            return;
        }

        try {
            const token = localStorage.getItem('token');
            await axios.delete(
                `https://homedoptwebsite-1.onrender.com/api/users/${userId}`,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            fetchUsers();
        } catch (err) {
            setError(t?.errors?.deleteError || '删除用户失败');
        }
    };

    const handleUpdateUser = async (userId, updates) => {
        try {
            const token = localStorage.getItem('token');
            const endpoint = userId
                ? `https://homedoptwebsite-1.onrender.com/api/users/${userId}`
                : 'https://homedoptwebsite-1.onrender.com/api/users';

            console.log('Sending user data:', updates);

            const response = await axios({
                method: userId ? 'put' : 'post',
                url: endpoint,
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                data: {
                    name: updates.name,
                    email: updates.email,
                    password: updates.password,
                    role: updates.role,
                    uplineId: updates.uplineId,
                    ic: updates.ic,
                    accountNo: updates.accountNo
                }
            });

            if (response.data) {
                fetchUsers();
                setEditingUser(null);
            }
        } catch (err) {
            console.error('Update error:', err);
            setError(userId ? t?.errors?.updateError : t?.errors?.createError);
        }
    };

    const getRoleText = (role) => {
        switch (role) {
            case 'admin': return t?.roles?.admin || "管理员";
            case 'boss': return t?.roles?.boss || "老板";
            case 'manager': return t?.roles?.manager || "经理";
            case 'leader': return t?.roles?.leader || "组长";
            default: return t?.roles?.agent || "代理商";
        }
    };

    // 修改排序控制组件
    const renderControls = () => (
        <div className="user-controls">
            <button
                className={`sort-button ${sortType === SORT_TYPES.TIME_ASC ? 'active' : ''}`}
                onClick={() => {
                    // 切换排序方式
                    const newSortType = sortType === SORT_TYPES.TIME_ASC ? SORT_TYPES.TIME_DESC : SORT_TYPES.TIME_ASC;
                    setSortType(newSortType);
                    
                    // 创建用户数组的副本
                    const sortedUsers = [...users];
                    
                    // 按角色和时间排序
                    sortedUsers.sort((a, b) => {
                        // 首先按角色排序
                        const roleComparison = ROLE_WEIGHTS[a.role] - ROLE_WEIGHTS[b.role];
                        
                        // 如果角色相同，则按时间排序
                        if (roleComparison === 0) {
                            const dateA = new Date(a.createdAt).getTime();
                            const dateB = new Date(b.createdAt).getTime();
                            
                            // 根据排序类型决定升序还是降序
                            return newSortType === SORT_TYPES.TIME_ASC ? 
                                dateA - dateB : 
                                dateB - dateA;
                        }
                        
                        return roleComparison;
                    });
                    
                    // 更新用户列表状态
                    setUsers([...sortedUsers]);  // 使用展开算符创建新数组以触发重新渲染
                    setUplineUsers([...sortedUsers]);  // 同时更新上线用户列表
                }}
            >
                {sortType === SORT_TYPES.TIME_ASC ? 
                    (t?.sort?.byTimeAsc || "较早创建") : 
                    (t?.sort?.byTimeDesc || "较新创建")
                }
            </button>
        </div>
    );

    // 修改批量删除函数
    const handleBatchDelete = async () => {
        if (selectedUsers.length === 0) return;
        
        if (!window.confirm(t?.messages?.confirmBatchDelete || '确定要删除选中的用户吗？')) {
            return;
        }

        try {
            const token = localStorage.getItem('token');
            console.log('Sending batch delete request:', selectedUsers); // 添加日志

            const response = await axios.post(
                'https://homedoptwebsite-1.onrender.com/api/users/batch-delete',
                { userIds: selectedUsers },
                {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            console.log('Batch delete response:', response.data); // 添加日志
            
            if (response.data.message) {
                // 刷新用户列表
                await fetchUsers();
                // 清空选中列表
                setSelectedUsers([]);
                // 显示成功消息
                alert(`成功删除 ${response.data.count} 个用户`);
            }
        } catch (err) {
            console.error('Batch delete error:', err);
            setError(err.response?.data?.message || t?.errors?.batchDeleteError || '批量删除用户失败');
        }
    };

    // 添加选择用户函数
    const handleSelectUser = (userId) => {
        setSelectedUsers(prev => {
            if (prev.includes(userId)) {
                return prev.filter(id => id !== userId);
            } else {
                return [...prev, userId];
            }
        });
    };

    // 添加全选函数
    const handleSelectAll = () => {
        if (selectedUsers.length === users.length) {
            setSelectedUsers([]);
        } else {
            setSelectedUsers(users.map(user => user.id));
        }
    };

    // 添加上线搜索处理函数
    const handleUplineSearch = (searchText) => {
        setUplineSearch(searchText);
        if (searchText.trim() === '') {
            setFilteredUplines([]);
            return;
        }
        
        // 过滤匹配的上线
        const matches = uplineUsers.filter(upline => 
            upline.name.toLowerCase().includes(searchText.toLowerCase()) ||
            upline.ic?.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredUplines(matches);
    };

    // 添加搜索过滤函数
    const filteredUsers = users.filter(user => {
        const searchLower = searchTerm.toLowerCase();
        return (
            user.name?.toLowerCase().includes(searchLower) ||
            user.email?.toLowerCase().includes(searchLower) ||
            user.ic?.toLowerCase().includes(searchLower) ||
            user.accountNo?.toLowerCase().includes(searchLower)
        );
    });

    if (!currentUser || currentUser.role !== 'admin') {
        return null;
    }

    return (
        <div className="user-management-page">
            <div className="user-management-container">
                <div className="header-actions">
                    <h1>{t?.title || "用户管理"}</h1>
                    <div className="action-buttons">
                        {selectedUsers.length > 0 && (
                            <button
                                className="batch-delete-button"
                                onClick={handleBatchDelete}
                            >
                                {t?.buttons?.batchDelete || `删除选中(${selectedUsers.length})`}
                            </button>
                        )}
                        <button
                            className="add-button"
                            onClick={handleAddUser}
                        >
                            {t?.buttons?.add || "添加用户"}
                        </button>
                    </div>
                </div>

                {renderControls()} {/* 添加控制组件 */}

                {/* 添加搜索框 */}
                <div className="search-container">
                    <input
                        type="text"
                        className="search-input"
                        placeholder={t?.search?.placeholder || "搜索用户名、邮箱、IC或银行账号..."}
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                {error && <div className="error-message">{error}</div>}

                {loading ? (
                    <div className="loading">{t?.loading || "加载中..."}</div>
                ) : (
                    <div className="users-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={selectedUsers.length === users.length}
                                            onChange={handleSelectAll}
                                        />
                                    </th>
                                    <th>{t?.table?.name || "用户名"}</th>
                                    <th>{t?.table?.ic || "IC"}</th>
                                    <th>{t?.table?.accountNo || "银行账号"}</th>
                                    <th>{t?.table?.email || "邮箱"}</th>
                                    <th>{t?.table?.role || "角色"}</th>
                                    <th>{t?.table?.upline || "上线"}</th>
                                    <th>{t?.table?.actions || "操作"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {editingUser?.id === null && (
                                    <tr className="new-user-row">
                                        <td>
                                            <input
                                                type="checkbox"
                                                disabled
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editingUser.name}
                                                onChange={(e) => setEditingUser({
                                                    ...editingUser,
                                                    name: e.target.value
                                                })}
                                                placeholder={t?.placeholders?.name || "用户名"}
                                                className="edit-input"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editingUser.ic}
                                                onChange={(e) => setEditingUser({
                                                    ...editingUser,
                                                    ic: e.target.value
                                                })}
                                                placeholder={t?.placeholders?.ic || "IC"}
                                                className="edit-input"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="text"
                                                value={editingUser.accountNo}
                                                onChange={(e) => setEditingUser({
                                                    ...editingUser,
                                                    accountNo: e.target.value
                                                })}
                                                placeholder={t?.placeholders?.accountNo || "银行账号"}
                                                className="edit-input"
                                            />
                                        </td>
                                        <td>
                                            <input
                                                type="email"
                                                value={editingUser.email}
                                                onChange={(e) => setEditingUser({
                                                    ...editingUser,
                                                    email: e.target.value
                                                })}
                                                placeholder={t?.placeholders?.email || "邮箱"}
                                                className="edit-input"
                                            />
                                        </td>
                                        <td>
                                            <select
                                                value={editingUser.role}
                                                onChange={(e) => setEditingUser({
                                                    ...editingUser,
                                                    role: e.target.value
                                                })}
                                                className="edit-select"
                                            >
                                                <option value="agent">{getRoleText('agent')}</option>
                                                <option value="leader">{getRoleText('leader')}</option>
                                                <option value="manager">{getRoleText('manager')}</option>
                                                <option value="boss">{getRoleText('boss')}</option>
                                                <option value="admin">{getRoleText('admin')}</option>
                                            </select>
                                        </td>
                                        <td>
                                            <div className="upline-search-container">
                                                <input
                                                    type="text"
                                                    className="edit-input"
                                                    value={uplineSearch}
                                                    onChange={(e) => handleUplineSearch(e.target.value)}
                                                    placeholder={t?.placeholders?.searchUpline || "搜索上线..."}
                                                />
                                                {filteredUplines.length > 0 && (
                                                    <div className="upline-suggestions">
                                                        {filteredUplines.map(upline => (
                                                            <div
                                                                key={upline.id}
                                                                className="upline-suggestion-item"
                                                                onClick={() => {
                                                                    setEditingUser({
                                                                        ...editingUser,
                                                                        uplineId: upline.id
                                                                    });
                                                                    setUplineSearch(upline.name);
                                                                    setFilteredUplines([]);
                                                                }}
                                                            >
                                                                <span>{upline.name}</span>
                                                                <span className="upline-ic">{upline.ic}</span>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action-buttons">
                                                <button
                                                    className="save-button"
                                                    onClick={() => handleUpdateUser(null, editingUser)}
                                                >
                                                    {t?.buttons?.create || "创建"}
                                                </button>
                                                <button
                                                    className="cancel-button"
                                                    onClick={() => setEditingUser(null)}
                                                >
                                                    {t?.buttons?.cancel || "取消"}
                                                </button>
                                            </div>
                                            <input
                                                type="password"
                                                placeholder={t?.placeholders?.password || "密码"}
                                                value={editingUser.password}
                                                onChange={(e) => setEditingUser({
                                                    ...editingUser,
                                                    password: e.target.value
                                                })}
                                                className="edit-input"
                                            />
                                        </td>
                                    </tr>
                                )}
                                {filteredUsers.map(user => (
                                    <tr key={user.id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedUsers.includes(user.id)}
                                                onChange={() => handleSelectUser(user.id)}
                                            />
                                        </td>
                                        <td>
                                            {editingUser?.id === user.id ? (
                                                <input
                                                    type="text"
                                                    value={editingUser.name}
                                                    onChange={(e) => setEditingUser({
                                                        ...editingUser,
                                                        name: e.target.value
                                                    })}
                                                    className="edit-input"
                                                />
                                            ) : (
                                                user.name
                                            )}
                                        </td>
                                        <td>
                                            {editingUser?.id === user.id ? (
                                                <input
                                                    type="text"
                                                    value={editingUser.ic}
                                                    onChange={(e) => setEditingUser({
                                                        ...editingUser,
                                                        ic: e.target.value
                                                    })}
                                                    className="edit-input"
                                                />
                                            ) : (
                                                user.ic
                                            )}
                                        </td>
                                        <td>
                                            {editingUser?.id === user.id ? (
                                                <input
                                                    type="text"
                                                    value={editingUser.accountNo}
                                                    onChange={(e) => setEditingUser({
                                                        ...editingUser,
                                                        accountNo: e.target.value
                                                    })}
                                                    className="edit-input"
                                                />
                                            ) : (
                                                user.accountNo
                                            )}
                                        </td>
                                        <td>
                                            {editingUser?.id === user.id ? (
                                                <input
                                                    type="email"
                                                    value={editingUser.email}
                                                    onChange={(e) => setEditingUser({
                                                        ...editingUser,
                                                        email: e.target.value
                                                    })}
                                                    className="edit-input"
                                                />
                                            ) : (
                                                user.email
                                            )}
                                        </td>
                                        <td>
                                            {editingUser?.id === user.id ? (
                                                <select
                                                    value={editingUser.role}
                                                    onChange={(e) => setEditingUser({
                                                        ...editingUser,
                                                        role: e.target.value
                                                    })}
                                                    className="edit-select"
                                                >
                                                    <option value="agent">{getRoleText('agent')}</option>
                                                    <option value="leader">{getRoleText('leader')}</option>
                                                    <option value="manager">{getRoleText('manager')}</option>
                                                    <option value="boss">{getRoleText('boss')}</option>
                                                    <option value="admin">{getRoleText('admin')}</option>
                                                </select>
                                            ) : (
                                                <span className={`role-badge ${user.role}`}>
                                                    {getRoleText(user.role)}
                                                </span>
                                            )}
                                        </td>
                                        <td>
                                            {editingUser?.id === user.id ? (
                                                <div className="upline-search-container">
                                                    <label>{t?.table?.upline || "上线"}</label>
                                                    <input
                                                        type="text"
                                                        className="edit-input"
                                                        value={uplineSearch}
                                                        onChange={(e) => handleUplineSearch(e.target.value)}
                                                        placeholder={t?.placeholders?.searchUpline || "搜索上线..."}
                                                    />
                                                    {filteredUplines.length > 0 && (
                                                        <div className="upline-suggestions">
                                                            {filteredUplines.map(upline => (
                                                                <div
                                                                    key={upline.id}
                                                                    className="upline-suggestion-item"
                                                                    onClick={() => {
                                                                        setEditingUser({
                                                                            ...editingUser,
                                                                            uplineId: upline.id
                                                                        });
                                                                        setUplineSearch(upline.name);
                                                                        setFilteredUplines([]);
                                                                    }}
                                                                >
                                                                    <span>{upline.name}</span>
                                                                    <span className="upline-ic">{upline.ic}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                uplineUsers.find(u => u.id === user.uplineId)?.name || '-'
                                            )}
                                        </td>
                                        <td>
                                            {editingUser?.id === user.id ? (
                                                <div className="edit-form">
                                                    <input
                                                        type="password"
                                                        placeholder={t?.placeholders?.newPassword || "新密码（可选）"}
                                                        value={editingUser.password || ''}
                                                        onChange={(e) => setEditingUser({
                                                            ...editingUser,
                                                            password: e.target.value
                                                        })}
                                                        className="edit-input"
                                                    />
                                                    <div className="action-buttons">
                                                        <button
                                                            className="save-button"
                                                            onClick={() => handleUpdateUser(user.id, editingUser)}
                                                        >
                                                            {t?.buttons?.save || "保存"}
                                                        </button>
                                                        <button
                                                            className="cancel-button"
                                                            onClick={() => setEditingUser(null)}
                                                        >
                                                            {t?.buttons?.cancel || "取消"}
                                                        </button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="action-buttons">
                                                    <button
                                                        className="edit-button"
                                                        onClick={() => handleEditUser(user)}
                                                    >
                                                        {t?.buttons?.edit || "编辑"}
                                                    </button>
                                                    <button
                                                        className="delete-button"
                                                        onClick={() => handleDeleteUser(user.id)}
                                                    >
                                                        {t?.buttons?.delete || "删除"}
                                                    </button>
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default UserManagement; 