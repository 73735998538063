import React from 'react';
import './Rental.css';
import rental1 from '../images/rental1.png';
import rental2 from '../images/rental2.png';
import rental3 from '../images/rental3.png';
import rentalvs1 from '../images/rentalvs1.png';
import rentalvs2 from '../images/rentalvs2.png';
import homedoptLogo from '../images/HOMEDOPT-1024x1024-001-768x768.png';
import { translations } from '../locales/translations';

function Rental({ language = 'cn' }) {
  const t = translations[language]?.rental;

  if (!t) return null;

  return (
    <div className="rental-page">
      {/* 英雄区域 */}
      <section className="rental-hero">
        <div className="hero-content">
          <h1>{t.hero.title}</h1>
          <p className="subtitle">{t.hero.subtitle}</p>
        </div>
      </section>

      {/* 特色区域 */}
      <section className="rental-features">
        <div className="features-container">
          <div className="feature-card">
            <div className="card-image">
              <img src={rental1} alt={t.features.card1.title} />
            </div>
            <div className="card-content">
              <h3>{t.features.card1.title}</h3>
              <p>{t.features.card1.description}</p>
            </div>
          </div>

          <div className="feature-card">
            <div className="card-image">
              <img src={rental2} alt={t.features.card2.title} />
            </div>
            <div className="card-content">
              <h3>{t.features.card2.title}</h3>
              <p>{t.features.card2.description}</p>
            </div>
          </div>

          <div className="feature-card">
            <div className="card-image">
              <img src={rental3} alt={t.features.card3.title} />
            </div>
            <div className="card-content">
              <h3>{t.features.card3.title}</h3>
              <p>{t.features.card3.description}</p>
            </div>
          </div>
        </div>
      </section>

      {/* 对比区域 */}
      <section className="rental-comparison">
        <h2>{t.comparison.title}</h2>
        <h3>{t.comparison.subtitle}</h3>
        
        <div className="comparison-container">
          <div className="comparison-card">
            <img src={rentalvs1} alt={t.comparison.normal} />
            <h4>{t.comparison.normal}</h4>
          </div>

          <div className="comparison-card">
            <img src={rentalvs2} alt={t.comparison.rrp} />
            <h4>{t.comparison.rrp}</h4>
          </div>
        </div>

        <p className="comparison-text">{t.comparison.description}</p>
      </section>

      {/* 结束区域 */}
      <section className="rental-conclusion">
        <h2>{t.conclusion.title}</h2>
        <p>{t.conclusion.description}</p>
        <img src={homedoptLogo} alt="HOMEDOPT Logo" className="conclusion-logo" />
      </section>
    </div>
  );
}

export default Rental; 