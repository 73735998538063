import React, { useState } from 'react';
import './Adoption.css';
import { translations } from '../locales/translations';
import adopter from '../images/adopter.jpg';
import houseowner from '../images/houseowner.jpg';
import sellsmallhouse from '../images/sellsmallhouse.jpg';
import migration from '../images/migration.jpg';
import emer1 from '../images/emer1.gif';
import emer2 from '../images/emer2.jpg';
import emer3 from '../images/emer3.jpg';
import emer4 from '../images/emer4.jpg';
import emer5 from '../images/emer5.jpg';
import emer6 from '../images/emer6.jpg';
import { useNavigate } from 'react-router-dom';

function Adoption({ language = 'cn' }) {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const t = translations[language]?.adoption;

  if (!t) return null;

  const handleConsultationClick = () => {
    navigate('/contact');
  };

  const emergencySlides = [
    { image: emer1, text: t.scenarios?.cards[2]?.reasons[0] || "No Work" },
    { image: emer2, text: t.scenarios?.cards[2]?.reasons[1] || "Commitment" },
    { image: emer3, text: t.scenarios?.cards[2]?.reasons[2] || "Gas Price" },
    { image: emer4, text: t.scenarios?.cards[2]?.reasons[3] || "Sickness" },
    { image: emer5, text: t.scenarios?.cards[2]?.reasons[4] || "Inflation" },
    { image: emer6, text: t.scenarios?.cards[2]?.reasons[5] || "Education" }
  ];

  return (
    <div className="adoption-page">
      {/* Hero Section */}
      <section className="adoption-hero">
        <div className="container">
          <h1>{t.hero?.title || "Home Adoption"}</h1>
          <p className="hero-subtitle">{t.hero?.subtitle}</p>
          <p className="hero-highlight">{t.hero?.highlight}</p>
          <div className="hero-marquee">
            <span>{t.hero?.marquee}</span>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="cta-section">
        <div className="container">
          <h2>{t.cta?.title}</h2>
          <div className="benefits-list">
            {t.cta?.benefits.map((benefit, index) => (
              <div key={index} className="benefit-item">
                <span className="check-icon">✅</span>
                <p>{benefit}</p>
              </div>
            ))}
          </div>
          <p className="cta-note">{t.cta?.note}</p>
          <button className="cta-button" onClick={handleConsultationClick}>
            {t.cta?.button}
          </button>
        </div>
      </section>

      {/* Scenarios Section */}
      <section className="scenarios-section">
        <div className="container">
          <h2>{t.scenarios?.title}</h2>
          <div className="scenarios-grid">
            {t.scenarios?.cards.map((card, index) => (
              <div key={index} className="scenario-card">
                {index === 0 && (
                  <div className="scenario-image">
                    <img src={sellsmallhouse} alt={card.title} />
                  </div>
                )}
                {index === 1 && (
                  <div className="scenario-image">
                    <img src={migration} alt={card.title} />
                  </div>
                )}
                {index === 2 && (
                  <div className="scenario-image">
                    <div className="emergency-slider">
                      <div className="slider-content" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                        {emergencySlides.map((slide, idx) => (
                          <div key={idx} className="slide">
                            <img src={slide.image} alt={slide.text} className="slide-image" />
                            <p className="slide-text">{slide.text}</p>
                          </div>
                        ))}
                      </div>
                      <div className="slider-dots">
                        {emergencySlides.map((_, idx) => (
                          <button
                            key={idx}
                            className={`dot ${currentSlide === idx ? 'active' : ''}`}
                            onClick={() => setCurrentSlide(idx)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <h3>{card.title}</h3>
                <p>{card.desc}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* What is Home Adoption Section */}
      <section className="what-is-section">
        <div className="container">
          <h2>{t.whatIs?.title}</h2>
          <div className="model-explanation">
            <p>{t.whatIs?.subtitle}</p>
            <div className="model-comparison">
              {t.whatIs?.comparison.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits-section">
        <div className="container">
          {/* Adopter Benefits */}
          <div className="adopter-benefits">
            <div className="benefits-image">
              <img src={adopter} alt="Adopter Benefits" />
            </div>
            <div className="benefits-content">
              <div className="benefits-header">
                <span className="benefits-badge">{t.benefits?.adopter?.title}</span>
                <h3>{t.benefits?.adopter?.subtitle}</h3>
              </div>
              <div className="benefits-list">
                {t.benefits?.adopter?.items.map((item, index) => (
                  <div key={index} className="benefit-item">
                    <span className="benefit-icon">{item.icon}</span>
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
              <div className="benefits-note">
                <p>{t.benefits?.adopter?.note}</p>
                <button className="cta-button" onClick={handleConsultationClick}>
                  {t.benefits?.adopter?.button}
                </button>
              </div>
            </div>
          </div>

          {/* House Owner Benefits */}
          <div className="owner-benefits">
            <div className="benefits-image">
              <img src={houseowner} alt="House Owner Benefits" />
            </div>
            <div className="benefits-content">
              <div className="benefits-header">
                <span className="benefits-badge">{t.benefits?.owner?.title}</span>
                <h3>{t.benefits?.owner?.subtitle}</h3>
              </div>
              <div className="benefits-list">
                {t.benefits?.owner?.items.map((item, index) => (
                  <div key={index} className="benefit-item">
                    <span className="benefit-icon">{item.icon}</span>
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
              <div className="benefits-note">
                <p>{t.benefits?.owner?.note}</p>
                <button className="cta-button" onClick={handleConsultationClick}>
                  {t.benefits?.owner?.button}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Financial Disclaimer Section */}
      <section className="disclaimer-section">
        <div className="container">
          <div className="disclaimer-content">
            <h2>{t.disclaimer?.title}</h2>
            <p className="disclaimer-intro">{t.disclaimer?.intro}</p>
            {t.disclaimer?.blocks.map((block, index) => (
              <div key={index} className="disclaimer-block">
                <h3>{block.title}</h3>
                <p>{block.content}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Adoption; 