export const translations = {
  cn: {
    // 导航栏
    nav: {
      about: "关于我们",
      services: "服务项目",
      contact: "联系我们",
      career: "加入我们",
      faq: "常见问题",
      consult: "免费咨询",
      login: "登录",
      logout: "退出登录",
      profile: "个人中心",
      userManagement: "用户管理"
    },
    // 服务项目下拉菜单
    serviceMenu: {
      adoption: "家庭领养计划",
      asset: "资产回馈计划",
      mortgage: "房贷压力的解决方案",
      advance: "资产预付计划",
      rental: "租金回扣计划 (RRP)"
    },
    // 英雄区域
    hero: {
      badge: "马来西亚首创房屋领养计划",
      certification: "受政府认证机构",
      title: "专业守护",
      subtitle: "您的安居梦想",
      description: "专业房产保障方案 · 灵活付款计划 · 七年专业经验",
      buttons: {
        consult: "免费咨询",
        more: "了解更多"
      }
    },
    // 服务部分
    services: {
      title: "我们的服务",
      subtitle: "专业的房产保障解决方案",
      items: [
        {
          title: "评估",
          description: "专业房屋领养评估服务",
          icon: "🏠"
        },
        {
          title: "顾问",
          description: "持牌房屋领养顾问",
          icon: "📋"
        },
        {
          title: "保障",
          description: "严密的资产保护机制",
          icon: "🔒"
        },
        {
          title: "多元",
          description: "灵活的房屋领养方案",
          icon: "📈"
        }
      ]
    },
    // 价值观部分
    values: {
      title: "我们的价值观",
      subtitle: "为您提供专业的服务",
      forOwners: {
        title: "对于业主",
        description: "我们致力于了解您的每一个需求，并将创造最好的结构和解决方案，以确保您的家得到保留，并为您和您所爱的人带来最大的利益。"
      },
      forClients: {
        title: "对于我们的客户",
        description: "我们致力于培养一种协作方法来解决您的问题，同时营造一个包容和健康的工作环境，使人们能够学习和取得更多成就。"
      },
      principles: [
        {
          title: "问责制",
          description: "我们以有效和效率以及可信赖的方式，连接被领养者和领养者。"
        },
        {
          title: "正直",
          description: "执行专业、客观、公正、可信赖的房屋领养计划"
        },
        {
          title: "可靠性",
          description: "我们以高度的责任心，提供即时、准确、有用的清晰信息"
        }
      ],
      proven: "实力证明",
      transparent: "透明可靠的服务"
    },
    // 见证部分
    testimonials: {
      title: "原屋主的故事",
      subtitle: "真实客户的真实体验",
      stories: [
        {
          content: "在 MCO 期间和 MCO 之后，我的业务受到严重影响。当我的朋友介绍HOMEDOPT房屋领养计划时，起初我持怀疑态度，但经过详细了解后，我感到非常欣慰。",
          author: "MR KHOO",
          location: "TAMAN MUHIBBAH, KAPAR, SELANGOR"
        },
        {
          content: "我和母亲与两个兄弟姐妹住在增江北区。当母亲生病时，我必须停止工作。我的收入来源受到影响，住房贷款被断供了7个月。",
          author: "萝诗姐",
          location: "甲洞，曾江北区，柏灵殷花园"
        },
        {
          content: "4年前，我在一家信贷公司成为表弟贷款的担保人。因为他的食品店生意不佳而无法还款，未偿还的贷款落在我身上。",
          author: "CIK LIZA",
          location: "TAMAN DESA JAYA, KEPONG, KL"
        }
      ]
    },
    // 统计数据
    stats: {
      experience: "多年经验",
      adoptions: "房屋被领养",
      saved: "保住了家园"
    },
    about: {
      badge: "全马首创",
      title: "房屋领养计划",
      description: "自2016年以来，HOMEDOPT是全马首个也是唯一的房屋领养计划平台。我们专注于保护正在经历止赎程序的房屋。",
      features: [
        {
          title: "连贯的服务",
          description: "专业团队全程跟进，确保服务质量"
        },
        {
          title: "工作进度协调",
          description: "高效透明的流程管理"
        },
        {
          title: "现代化概念",
          description: "创新的解决方案"
        }
      ],
      board: {
        title: "创始人简介",
        directors: [
          {
            name: "YK Neo",
            role: "集团董事总经理",
            desc: "YK Neo 是 Homedopt 的远见卓识的领袖和联合创始人，Homedopt 是马来西亚首个房屋领养计划平台。\n\n" +
                 "凭借在房地产管理和房地产解决方案方面的丰富经验，Neo 对房地产市场和房主面临的挑战有深入的了解。他的专业知识涵盖房地产收购、市场趋势分析和创新住房支持策略，这些都是塑造 Homedopt 成功的关键。\n\n" +
                 "Neo 致力于改革住房生态系统，致力于防止止赎和促进可持续的房屋所有权。他的领导理念植根于责任、创新和诚信，确保 Homedopt 为领养者和被领养者提供有影响力和可靠的服务。\n\n" +
                 "Homedopt 已经保护了无数房屋，并推出了开创性的计划，重新定义了马来西亚的住房支持。他丰富的房地产专业知识和战略眼光继续激励团队在房地产领域创新和追求卓越。\n\n" +
                 "Neo 始终致力于推动行业的有意义变革，帮助个人和家庭实现房屋所有权梦想。"
          },
          {
            name: "Alan Tang Kiah Ming",
            role: "首席执行董事",
            desc: "Alan Tang Kiah Ming 是马来西亚房地产行业的资深企业家和公认专家，在房地产管理、营销和投资领域拥有超过30年的丰富经验。\n\n" +
                 "Alan 于2016年开始作为独立房地产代理人的职业生涯，在此期间他磨练了房地产交易和客户关系管理的专业知识。随后他担任 PSOS Agency 的经理，成功开发创新的营销策略，提升了房地产推广效果并实现显著的业务增长。\n\n" +
                 "2022年，Alan 与 YK Neo 共同创立了 Homedopt Sdn Bhd，这是一家致力于革新房屋领养计划的开创性公司。在此成功基础上，两人于2023年成立了 Home Prop Management Services Sdn Bhd，YK Neo 和 Alan 现担任董事，引领公司在房地产管理和创新住房解决方案方面追求卓越。"
          }
        ]
      },
      team: {
        financial: {
          title: "财务顾问",
          name: "Crystal Sia",
          role: "首席财务顾问",
          desc: "她负责管理财务资源，确保合规性和稳定性，同时提供专业的财务建议。"
        },
        sales: {
          title: "销售总监",
          name: "Aaron Tay",
          role: "首席营销官",
          desc: "管理所有与营销活动相关的生产和实施。负责策略制定、分析和团队领导。"
        },
        legal: {
          title: "法律顾问",
          name: "Ms. Grace",
          role: "首席法律顾问",
          desc: "她负责合同和法律问题的指导保护HOMEDOPT的利益并确保合规。"
        }
      },
      logo: {
        meaning: {
          title: "品牌标志含义",
          desc1: "Homedopt 是由 Home 和 Adopt 组合而成的词。",
          desc2: "我们的使命是通过房屋领养计划帮助有需要的人。"
        },
        brandmark: {
          title: "品牌标志结构",
          desc: "我们的标志由两个主要元素组成，象征着家和希望。"
        }
      },
      vision: {
        title: "我们的愿景",
        desc: "成为马来西亚最值得信赖的房屋领养平台。"
      },
      mission: {
        title: "我们的使命",
        desc: "我们致力于通过创新的政策和实践来减少房屋拍卖，为所有人创造可持续的未来。"
      },
      values: {
        title: "我们的核心价值观",
        intro: "我们致力于确保所有人都能享有繁荣和充实的生活，确保经济、社会和技术进步和谐发展。",
        care: {
          title: "我们关心、学习和分享",
          desc: "我们对彼此的目标和目的充满热。在前进的过程中，我们对所有计算好的风险都非常谨慎，在前进的过程中，我们学习、成长，并从经验中追求卓越。"
        },
        right: {
          title: "我们做正确的事，而不是容易的事",
          desc: "我们在所做的一切中都坚持诚信，为投资者、领养者和被领养者提供优质、有意义和受人尊重的服务。"
        }
      },
      teamInfo: {
        title: "管理团队成员",
        chartTitle: "公司组织架构图"  // 更新中文翻译
      },
      companyGroup: {
        title: "Homedopt 集团公司:",  // 添加中文翻译
        companies: [
          {
            name: "Homedopt Sdn Bhd",
            reg: "(1501540-M)"
          },
          {
            name: "Home Prop Management Services Sdn Bhd",
            reg: "(1508203-A)"
          }
        ]
      },
      companyHistory: {
        title: "我们的发展历程",  // 或者可以选择以下其他标题:
        // title: "品牌故事" 
        // title: "企业发展历程"
        // title: "从愿景到现实"
        paragraphs: [
          "作为马来西亚首家房屋领养公司，Homedopt Malaysia 以创新方式重新定义了房地产管理和所有权。自2017年成立以来，我们的创始人通过个人实践，以非正式的方式帮助了许多面临房产拍卖困境的开发商和业主。",
          
          "怀着帮助社区促进可持续生活、振兴社区和为所有人提供可及住房解决方案的使命热情，业务规模逐渐扩大。为满足市场需求，HOMEDOPT RESOURCES 于2022年成立。",
          
          "通过承接第一个开发商项目，共77套房产被领养，因此 Homedopt Sdn Bhd 于2023年注册成立，以处理更大规模的业务。这些经验使我们专注于保护正在经历止赎程序的房屋。我们经验丰富的专业团队确保每次领养都得到法律、财务和维护指导，使整个过程透明无忧。",
          
          "Homedopt Malaysia 架起了传统房地产实践与现代需求之间的桥梁，让领养者能够创造充满活力的社区，同时推动经济增长。Homedopt Malaysia 不仅仅是一个企业——它是重新定义21世纪房屋所有权的一场运动。亚洲首个房屋领养平台！"
        ]
      },
      buttons: {
        readMore: "查看更多",
        collapse: "收起"
      }
    },
    footer: {
      description: "Homedopt 自2016年以来是马来西亚首创也是唯一的房屋领养规划平台。我们专注于保护正在经历止赎程序的房屋。",
      offices: {
        headquarters: "总部办公室",
        penang: "槟城办公室",
        johor: "柔佛办公室"
      }
    },
    adoption: {
      hero: {
        title: "房屋领养计划",
        subtitle: "我们已经帮助超过500+工作人士成功实现",
        highlight: "零成本获得固定定的被动收入",
        marquee: "房屋领养计划 • 房屋领养计划 •"
      },
      cta: {
        title: "立即预约免费一对一咨询！",
        benefits: [
          "首付，Homedopt为您承担。",
          "保险，Homedopt为您承担。",
          "租客，Homedopt为您承担。",
          "只需5年，您就能每月收取租金！"
        ],
        note: "免费咨询名额有限，立即预约！",
        button: "立即预约免费一对一咨询"
      },
      scenarios: {
        title: "本地房地产市场最常见的3种情况",
        cards: [
          {
            title: "卖小房换大房",
            desc: "收入较高，可以卖掉小房子换取更大的房子。"
          },
          {
            title: "城际或海外迁移",
            desc: "因学习或工作需要搬迁到国外或其他城市"
          },
          {
            title: "紧急资产出售",
            desc: "许多负面因素可能导致您的资产价值降低。",
            reasons: ["无工作", "承诺", "油价", "疾病", "通货膨胀", "教育"]
          }
        ]
      },
      whatIs: {
        title: "什么是房屋领养？",
        subtitle: "我们的模式类似于：",
        comparison: [
          "Grab – 有司机，有乘客",
          "HOMEDOPT – 有房主（原业主），有客户（领养者）"
        ]
      },
      benefits: {
        adopter: {
          title: "领养者",
          subtitle: "对于领养（客户），以下是好处：",
          items: [
            {
              icon: "💰",
              text: "您可以每月收取RM 500+至RM 1,500+的领养费，类似租金收入！"
            },
            {
              icon: "✨",
              text: "您无需支付大量的印花税、律师费、首付或保险费！"
            },
            {
              icon: "⏰",
              text: "您无需被绑定35年，只需5年就能享受稳定的领养费，类似租金收入！"
            }
          ],
          note: "想知道如何不买房也能收取租金收入？点击免费咨询！我们将安排专家为您解释！",
          button: "立即预约免费一对一咨询"
        },
        owner: {
          title: "房主",
          subtitle: "对于原业主，以下是好处：",
          items: [
            {
              icon: "💎",
              text: "您可以套现高达40%！"
            },
            {
              icon: "🌟",
              text: "您可以用5年0%利息进行资金周转！"
            },
            {
              icon: "🎯",
              text: "您无需每月还款。"
            }
          ],
          note: "想知道如何在不卖房的情况下套现高达40%？点击免费咨询！我们将安排专家为您解释！",
          button: "立即预约免费一对一咨询"
        }
      },
      disclaimer: {
        title: "免责声明",
        intro: "以下全面的财务免责声明涉及HOMEDOPT房屋领养计划。该计划已帮助超过500+工作人士实现\"零成本获得稳定的被动收入\"。在参与HOMEDOPT房屋领养计划之前，充分了解该计划、其潜在收益和相关财务风险至关重要。",
        blocks: [
          {
            title: "简介",
            content: "HOMEDOPT房屋领养计划为寻求无需大额前期资本投资即可获得被动收入的个人提供独特机会。虽然该计划拥有诸多优势，但充分了解参与该计划的财务细节和潜在风险至关重要。"
          },
          {
            title: "收入保证和投资考虑",
            content: "HOMEDOPT房屋领养计划的主要吸引力之一是稳定被动收入的承诺。需要认识到，尽管有这些保证，但您可能获得的具体收入并非保证。收入水平可能因市场条件、当地租赁需求和个人财务状况等因素而显著变化。潜在参与者应清楚了解其财务回报可能随时间波动。"
          },
          {
            title: "投资风险",
            content: "HOMEDOPT房屋领养计划与所有投资一样，都存在固有风险。它基本建立在房地产领养的基础上，房产价值可能会升值也可能会贬值。投资报受市场波动影响，因此个人在参与前必须评估其风险承受能力和财务目标。此外，经济衰退或房地产市场变化等意外发展可能影响投资结果。"
          },
          {
            title: "法律和财务咨询",
            content: "在加入HOMEDOPT房屋领养计划之前，寻求合格专业人士的法律、财务和税务建议是关键一步。房地产交易和收入产生可能涉及复杂的法律和财务影响，因地区而异。为保护您的利益，强烈建议您全面了解计划条款、潜在税务责任和管理房产交易的法律框架。"
          },
          {
            title: "结果可能不同",
            content: "虽然计划的领养者成功案例展示了稳定收入的潜力，但这些例子不应被误解为预测您的具体结果。每个参与者的财务状况都是独特的，包括位置、房产类型和市场动态在内的各因素都可能影响收入水平。"
          },
          {
            title: "过往表现",
            content: "过往表现不应作为预测未来结果的唯一依据。房地产市场和经济状况会随时间变化，投资表现可能与历史先例不同。因此，个人不应仅基于过往表现数据来设定期望。"
          },
          {
            title: "HOMEDOPT房屋领养计划的角色",
            content: "HOMEDOPT房屋领养计划的特点是承担首付、保险和与房产领养相关的各种其他费用。必须理解，这种财务援助并不能消除投资风险。参与者应进行广的尽职调查，熟悉划的条款和条件，并了解潜在的限制和责任。"
          },
          {
            title: "个人财务状况",
            content: "HOMEDOPT房屋领养计划的参与者在开始这段旅程之前必须考虑其独特的财务状况、目标和风险承受能力。个人财务健康应是首要考虑因素。计划的适用性可能因个人的目标和情况而异，因此必须根据您的具体需求定制投资决策。"
          },
          {
            title: "确认和注意事项",
            content: "通过参与HOMEDOPT房屋领养计划提供的信息和服务，您确认您完全理解与房地产领养和财务决策相关的风险。您有责任做出明智的选择，在必要时寻求专业建议，并仔细权衡潜在收益与固有风险。HOMEDOPT房屋领养计划对您的投资结果不承担任何责任。建议您以谨慎、远见和对充分研究的决策过程的承诺来对待这个机会。"
          }
        ]
      }
    },
    asset: {
      hero: {
        title: "资产回馈计划",
        subtitle: "以房养老, 让您的房产为您服务！",
        button: "了解更多"
      },
      intro: {
        title: "关于资产回馈计划",
        desc1: "Homedopt是专为解决资产和现金流问题而诞生的平台",
        desc2: "我们的资产回馈计划为屋主提供稳定的合法收入. 每年高达6%合法收入, 将您的房产转化为稳定现金流",
        desc3: "我们提供专业支持和稳定收益, 通过严格的内部控制确保您的资产安全可靠",
        benefits: {
          income: {
            title: "稳定每月收入",
            desc: "每月领取固定的养老金"
          },
          ownership: {
            title: "保留房产",
            desc: "继续拥有您的房产权益"
          }
        }
      },
      example: {
        title: "房屋回馈计划示例",
        items: {
          propertyValue: {
            title: "房产价值",
            value: "RM500,000"
          },
          monthlyReceive: {
            title: "每月收入",
            value: "RM2,662.50"
          },
          yearlyReceive: {
            title: "年度收入",
            value: "RM31,950.00"
          },
          total: {
            title: "总收入",
            value: "RM159,750"
          }
        }
      },
      faq: {
        title: "常见问题",
        items: [
          {
            question: "为什么以前是以儿养老现在却是以房养老？",
            answer: "随着社会结构和家庭关系的变化，越来越多的人面临独居或子女不在身边的情况。因此，以房养老计划为那些已经拥有房产、但希望在退休后获得额外收入的人提供了一个选择。"
          },
          {
            question: "资产回馈的好处是什么？",
            answer: "资产回馈计划可以为您提供一种稳定的额外收入来源，无需出售您的房产。您可以在保留房产所有权的同时获得每月的红利，为您的退休生活提供经济支持。"
          },
          {
            question: "每年能拿多少钱？",
            answer: "您每年可以拿到的金额取决于您的房产价值、您龄和其他因素。一般来说，您可以期望获得相当于房产价值一定百分比的额外收入，通常在3%至6%之间。"
          },
          {
            question: "我需要拥有完全的产权才能参与吗？",
            answer: "是的,您需要拥有完全的产权才能参与以房养老计划。"
          },
          {
            question: "房产还会是我的遗产吗？",
            answer: "是的，房产仍然是您的遗产。以房养老计划不会影响您对房产的所有权。您的房产可以作为您的遗产传给您的家人或受益人。"
          },
          {
            question: "我的房屋价值将如何确定？",
            answer: "您的房屋价值由专评估师进行评估，以确保公正和准确。"
          },
          {
            question: "我需要支付任何费用来参与这个计划吗？",
            answer: "参与计划需要支付管理费用, 具体情况请咨询我们的工作人员以获取准确信息"
          }
        ]
      }
    },
    mortgage: {
      hero: {
        title: "房贷压力的解决方案",
        subtitle: "我们提供5年免除房贷压力的解决方案！",
        desc: "让您在未来的五年里,无需为房贷支付而烦恼",
        button: "了解更"
      },
      intro: {
        title: "经济不景 房贷压力？",
        highlight: "高达5年 免房贷",
        desc1: "Homedopt是专为解决资产和现金流问题而诞生的平台",
        desc2: "我们的计划旨在帮助您应对房贷压力这种挑战, 让您在一段时间内免除房贷压力, 以减轻您的经济负担",
        desc3: "我们提供额外的流动资金支持,帮助您应对生活中的其他开支"
      },
      faq: {
        title: "常见问题",
        items: [
          {
            question: "经济不景，你的房贷有压力吗？",
            answer: "我们理解当前经济环境可能给您带来房贷压力，我们的计划旨在帮助您应对这种经济困境，让您在一段时间内免除房贷压力，以减轻您的经济负担。"
          },
          {
            question: "如何不必供五年？",
            answer: "参与我们的计划，您可以将您的房屋纳入计划中，使您在未来五年内不需要供房屋，同时我们提供额外的流动资金支持，帮助您应对生活中的其他额外开支。"
          },
          {
            question: "我还是房子的主人吗？",
            answer: "是的，您仍然是房屋的拥有者。这个计划旨在为您提供经济支持，以应对定时期的房贷压力，但您仍然拥有房屋的所有权掌控权。"
          },
          {
            question: "我需要提供哪些文件来参与计划？",
            answer: "请准备好您房屋所相关的产权证明和贷款合同等文件，具体的要求会根据您的情况而取。"
          },
          {
            question: "我可以选择免除房贷的时段吗？",
            answer: "目前我们的计划可以享有长达五年的免除房贷期限，如果您有特殊需求，请与我们的工作人员联系。"
          },
          {
            question: "我可以提前结束参与计划吗？",
            answer: "可以的，公司会有一些特定的条规，请联系我们的工作人员以获取详细的信息。"
          }
        ]
      }
    },
    advance: {
      hero: {
        title: "资产预付计划",
        subtitle: "解决资金瓶颈自由掌控生活",
        button: "了解更多"
      },
      intro: {
        title: "关于资产预付计划",
        desc1: "Homedopt是专为解决资产和现金流问题而诞生的平台",
        desc2: "我们的计划旨在帮助您应对有价无市的问题,让您更早获得1/3的资金作为资金周转",
        desc3: "我们提供额外的流动资金支持,帮助您应对生活中的其他开支"
      },
      faq: {
        title: "常见问题",
        items: [
          {
            question: "我的好处是什么？",
            answer: "您无需急着卖掉房产也可以获得您房产价值的1/3作为资金,房产的价值将由专业评估师进行评估"
          },
          {
            question: "我何时可以拿到这笔资金",
            answer: "一旦您同意了我们的方案并签署了相关文件,最快可以在个星期内完成交易,您就可以拿到资金了。"
          },
          {
            question: "我需要偿还这笔资金吗？",
            answer: "是的,这笔资金需要在5年后偿还,这包括了房产管理费. 无需担心, 期间仍然可以保留您的房产使用权."
          },
          {
            question: "这个方案适用于所有类型的房产吗？",
            answer: "这个方案适用于住宅房产, 包括独立屋、公寓和排屋.具体情况建议您与我们的顾问联系以获取更多详细信息."
          },
          {
            question: "这笔资金可以用于任何用途吗？",
            answer: "是的, 您可以自由支配这笔资金, 用于任何您需要的用途, 无论是支付账单、进行投资或是其他紧急支出"
          },
          {
            question: "我可以提前偿还这笔资金吗？",
            answer: "是的, 您可以选择提前偿还这笔资金,在这期间需要支付一定的提前偿还管理费用. 请在签署合同前详细了解相关条款."
          }
        ]
      }
    },
    rental: {
      hero: {
        title: "租金回扣计划 (RRP)",
        subtitle: "为租户量身定制的独家机会"
      },
      features: {
        card1: {
          title: "拯救家园",
          description: "专们为租户设计的计划，提供创造独特的被动收入机会。"
        },
        card2: {
          title: "参与拯救",
          description: "参与从拍卖中拯救房屋，同时赚取可观的回报。"
        },
        card3: {
          title: "财务投资组合",
          description: "通过参与这创新的计划，建立您的财务投资组合，以便在未来的借贷中获得良好的财务评分。"
        }
      },
      comparison: {
        title: "What's the differences between",
        subtitle: "正常租金 vs 租金回扣计划 (RRP)",
        normal: "正常租金",
        rrp: "租金回扣计划 (RRP)",
        description: "虽然正常的租金是租户直接向房东支付全额租金，但有时会遇到意想不到的问题，例如市场租金低、人们迟付租金甚至不租。HOMEDOPT 租金回扣计划为符合资格的个人或面临经济困难的家庭提供额外的经济援助或更好的租金解决方案。"
      },
      conclusion: {
        title: "HOMEDOPT 是来帮我们的!",
        description: "Yes, Rental Rebate Program can indeed be very helpful for tenants and offer an innovative solution to reduce rental costs effectively."
      }
    },
    contact: {
      hero: {
        title: "联系我们",
        subtitle: "我们随时准备为您提供专业的咨询服务"
      },
      info: {
        office: {
          title: "总部办公室",
          address: "G-27, G Floor, The Heritage, Jalan SB Dagang, Mines Wellness City, 43300 Seri Kembangan, Selangor."
        },
        phone: {
          title: "联系电话",
          number: "+60172013168",
          whatsapp: "点击通过WhatsApp联系我们"
        },
        email: {
          title: "电子邮件",
          address: "liewsylvia216@gmail.com"
        }
      },
      form: {
        title: "发送消息给我们",
        name: "您的姓名",
        email: "电子邮件",
        phone: "联系电话",
        subject: "主题",
        message: "请输入您的留言",
        submit: "发送消息"
      },
      map: {
        title: "我们的位置"
      }
    },
    career: {
      hero: {
        title: "加入我们，成为优秀团队的一员",
        position: "行政助理"
      },
      sections: {
        requirements: {
          title: "职位要求",
          items: [
            "欢迎无经验者申请，将提供培训",
            "至少SPM或以上学历",
            "优先考虑会说中文的申请者",
            "工作地点在 The Heritage, Seri Kembangan",
            "语言要求：马来语、中文、英语",
            "计算机技能 – 从数据录入到系统分析的相关技能",
            "团队协作能力 – 能在参与式管理环境中与他人协作；既能独立工作也能团队合作",
            "精通MS Office",
            "全面了解办公室管理系统和程序"
          ]
        },
        responsibilities: {
          title: "工作职责",
          items: [
            "作为管理层与内部/外部客户之间的联系人",
            "接听电话、记录信息和处理通信",
            "妥善处理请求和查询",
            "记录会议记录并准确输入数据",
            "监控办公用品",
            "建立和执行高效的文档和归档系统",
            "组织工作量，设定优先级并在截止日期内完成工作"
          ]
        },
        benefits: {
          title: "福利待遇",
          items: [
            "医疗和住院假",
            "法定扣除（EPF、SOCSO）",
            "额外佣金",
            "提供津贴",
            "职业发展",
            "奖金",
            "团队建设",
            "公司旅游"
          ]
        },
        additionalBenefits: {
          title: "额外福利",
          items: [
            "年假",
            "EPF / SOCSO / PCB",
            "绩效奖金"
          ]
        }
      },
      form: {
        title: "立即申请",
        fullName: "姓名",
        phone: "联系电话",
        email: "电子邮箱",
        nationality: "国籍",
        employmentStatus: {
          label: "工作经验",
          options: {
            select: "请选择",
            experienced: "有工作经验",
            fresh: "应届毕业生"
          }
        },
        fieldOfStudy: "学习领域",
        expectedSalary: "期望薪资 (RM)",
        reference: {
          label: "如何了解到我们",
          options: {
            select: "请选择",
            website: "公司网站",
            social: "社交媒体",
            referral: "推荐",
            other: "其他"
          }
        },
        resume: {
          label: "上传简历（可选）",
          hint: "可选，您也可以稍后通过邮件发送简历"
        },
        portfolio: {
          label: "上传作品集（可选）",
          hint: "可选，您也可以稍后通过邮件发送作品集"
        },
        submit: "提交申请",
        successMessage: "请在打开的邮件客户端中添加您的简历和作品集（如有），然后发送邮件。"
      }
    },
    faq: {
      hero: {
        title: "常见问题解答",
        subtitle: "找到您需要的所有答案"
      },
      search: {
        placeholder: "搜索问题...",
        noResults: "没有找到相关问题"
      },
      categories: {
        general: "一般问题",
        adoption: "房屋领养",
        asset: "资产回馈",
        mortgage: "房贷纾困",
        advance: "资产预付",
        rental: "租金回扣"
      },
      chat: {
        title: "Have More Questions?",
        description: "Our professional consultants are here to help",
        button: "Consult Now",
        floating: "Online Chat",
        openChat: "AI Assistant",
        welcome: "Hello! I'm HOMEDOPT's AI Assistant. How may I help you today?",
        inputPlaceholder: "Type your question..."
      },
      // 添加问题列表
      items: [
        {
          category: 'general',
          question: '什么是HOMEDOPT?',
          answer: 'HOMEDOPT是马来西亚首创的房屋领养平台，专注于为面临房贷压力的业主提供专业的房产保障解决方案。'
        },
        {
          category: 'adoption',
          question: '房屋领养计划是如何运作的？',
          answer: '房屋领养计划通过匹配房主和领养者，为双方提供互惠互利的解决方案。'
        },
        {
          category: 'asset',
          question: '资产回馈计划有什么好处？',
          answer: '资产回馈计划让房主无需出售房产就能获得资金支持，每年高达6%合法收入。'
        },
        {
          category: 'mortgage',
          question: '如何申请房贷纾困方案？',
          answer: '您可以通过我们的网站或联系我们的顾问来申请房贷纾困方案。'
        },
        {
          category: 'advance',
          question: '资产预付计划的申请条件是什么？',
          answer: '申请条件包括房产证满2年、房产无抵押、产权清晰等。'
        },
        {
          category: 'rental',
          question: '租金回扣计划如何帮助租户？',
          answer: '租金回扣计划为租户提供租金优惠和房产增值收益的机会。'
        }
      ]
    },
    login: {
      title: "欢迎回来",
      subtitle: "请登录您的账户",
      ic: "身份证号码",
      icPlaceholder: "请输入身份证号码",
      password: "密码",
      passwordPlaceholder: "请输入密码",
      rememberMe: "记住我",
      forgotPassword: "忘记密码？",
      loginButton: "登录",
      errors: {
        invalidCredentials: "身份证号码或密码错误",
        serverError: "服务器错误，请稍后重试",
        loginFailed: "登录失败，请重试"
      }
    },
    profile: {
      title: "个人中心",
      labels: {
        name: "用户名",
        email: "邮箱",
        role: "角色"
      },
      roles: {
        admin: "管理员",
        boss: "老板",
        manager: "经理",
        agent: "代理商"
      },
      downlines: {
        title: "团队成员",
        loading: "加载中...",
        error: "获取团队成员失败"
      },
      buttons: {
        changePassword: "修改密码"
      },
      passwordModal: {
        title: "修改密码",
        newPassword: "新密码",
        confirmPassword: "确认新密码",
        submit: "确认",
        cancel: "取消",
        success: "密码修改成功",
        errors: {
          mismatch: "两次输入的密码不一致",
          updateFailed: "密码修改失败"
        }
      }
    },
    userManagement: {
      title: "用户管理",
      loading: "加载中...",
      table: {
        name: "用户名",
        email: "邮箱",
        role: "角色",
        actions: "操作",
        upline: "上线",
        noUpline: "无上线",
        newPassword: "新密码",
        confirmPassword: "确认密码",
        accountNo: "银行账号",  // 添加银行账号翻译
        ic: "身份证号码"  // 添加 IC 翻译
      },
      roles: {
        admin: "管理员",
        boss: "老板",
        manager: "经理",
        leader: "组长",  // 添加中文翻译
        agent: "代理商"
      },
      buttons: {
        add: "添加用户",
        edit: "编辑",
        save: "保存",
        cancel: "取消",
        delete: "删除",
        create: "创建"
      },
      placeholders: {
        name: "请输入用户名",
        email: "请输入邮箱",
        password: "请输入密码",
        newPassword: "新密码（可选）",
        accountNo: "请输入银行账号",  // 添加银行账号占位符翻译
        ic: "请输入身份证号码"  // 添加 IC 占位符翻译
      },
      messages: {
        confirmDelete: "确定要删除此用户吗？",
        createSuccess: "创建用户成功",
        updateSuccess: "更新用户成功",
        deleteSuccess: "删除用户成功"
      },
      errors: {
        fetchError: "获取用户列表失败",
        createError: "创建用户失败",
        updateError: "更新用户失败",
        deleteError: "删除用户失败",
        emailExists: "邮箱已存在",
        nameExists: "用户名已存在",
        accessDenied: "没有权限执行此操作"
      },
      search: {
        placeholder: "搜索用户名、邮箱、IC或银行账号..."
      },
      sort: {
        byRole: "职位排序",  // 简化翻译
        byTimeAsc: "较早创建",
        byTimeDesc: "较新创建"
      }
    },
    teamInfo: {  // 原来是 team
      title: "我们的团队",
      description: "专业且富有经验的团队",
      // ... 其他团队相关翻译
    },
    teamMembers: {  // 原来是 team
      title: "团队成员",
      members: [
        // ... 团队成员列表
      ]
    }
  },
  en: {
    nav: {
      about: "About Us",
      services: "Services",
      contact: "Contact",
      career: "Career",
      faq: "FAQ",
      consult: "Free Consultation",
      login: "Login",
      logout: "Logout",
      profile: "Profile"
    },
    serviceMenu: {
      adoption: "HOME ADOPTION PLAN",
      asset: "HOUSE REVERSION PLAN",  // 已更新
      mortgage: "MORTGAGE RELIEF PLAN",
      advance: "ASSET PRE-PAYMENT PLAN",
      rental: "RENTAL REBATE PROGRAM (RRP)"
    },
    hero: {
      badge: "Malaysia's First Home Adoption Platform",
      certification: "Government Certified Institution",
      title: "Professional Protection",
      subtitle: "For Your Dream Home",
      description: "Professional Property Protection Plan · Flexible Payment Plan · 7 Years Experience",
      buttons: {
        consult: "Free Consultation",
        more: "Learn More"
      }
    },
    services: {
      title: "Our Services",
      subtitle: "Professional Property Protection Solutions",
      items: [
        {
          title: "Evaluation",
          description: "Professional Home Adoption Evaluation Service",
          icon: "🏠"
        },
        {
          title: "Consultant",
          description: "Licensed Home Adoption Consultant",
          icon: "📋"
        },
        {
          title: "Protection",
          description: "Strict Asset Protection Mechanism",
          icon: "🔒"
        },
        {
          title: "Diversity",
          description: "Flexible Home Adoption Solutions",
          icon: "📈"
        }
      ]
    },
    about: {
      badge: "Malaysia's First",
      title: "Home Adoption Plan",
      description: "Since 2016, HOMEDOPT has been Malaysia's first and only home adoption plan platform. We focus on protecting homes going through foreclosure.",
      features: [
        {
          title: "Continuous Service",
          description: "Professional team follows through to ensure service quality"
        },
        {
          title: "Progress Coordination",
          description: "Efficient and transparent process management"
        },
        {
          title: "Modern Concept",
          description: "Innovative solutions"
        }
      ],
      board: {
        title: "Founder's Profile",
        directors: [
          {
            name: "YK Neo",
            role: "Group Managing Director",
            desc: "YK Neo is a visionary leader and Co-Founder of Homedopt...\n\n" +
                 "With extensive experience in property management and real estate solutions, Neo has developed a deep understanding of the housing market and the challenges faced by homeowners. His expertise spans property acquisition, market trends analysis, and innovative housing support strategies, which have been instrumental in shaping Homedopt success.\n\n" +
                 "Driven by a passion for transforming the housing ecosystem, Neo has dedicated himself to preventing foreclosures and promoting sustainable homeownership. His leadership philosophy is anchored in accountability, innovation, and integrity, ensuring Homedopt delivers impactful and reliable services to adopters and adoptees alike.\n\n" +
                 "Homedopt has secured countless homes and introduced groundbreaking programs that redefine housing support in Malaysia. His extensive property expertise and strategic vision continue to inspire the team to innovate and achieve excellence in the real estate sector.\n\n" +
                 "Neo remains committed to driving meaningful change in the industry, helping individuals and families turn their homeownership dreams into reality."
          },
          {
            name: "Alan Tang Kiah Ming",
            role: "Chief Executive Director",
            desc: "Alan Tang Kiah Ming is a seasoned entrepreneur and a recognized expert in Malaysia's real estate industry, boasting over 30 years of comprehensive experience in property management, marketing, and investment.\n\n" +
                 "Alan began his journey as an independent property agent in 2016, where he honed his expertise in property transactions and client relationship management. He later served as the Manager of PSOS Agency, where he successfully developed innovative marketing strategies to enhance property promotions and achieve notable business growth.\n\n" +
                 "2022，Alan & YK Neo, co-founded Homedopt Sdn Bhd, a trailblazing company dedicated to revolutionizing home adoption programs. Building on this success, the duo established Home Prop Management Services Sdn Bhd in 2023, where YK Neo and Alan now serves as the Director, steering the company towards excellence in property management and innovative housing solutions."
          }
        ]
      },
      team: {
        financial: {
          title: "FINANCIAL ADVISOR",
          name: "Crystal Sia",
          role: "Head of Financial Advisor",
          desc: "She manages financial resources, ensuring compliance and stability while offering financial advice for informed decisions."
        },
        sales: {
          title: "SALES DIRECTOR",
          name: "Aaron Tay",
          role: "Chief Marketing Officer",
          desc: "Managing all aspects related to the production and implementation of any campaign. Strategizing, analyzing and leading the team."
        },
        legal: {
          title: "LEGAL ADVISOR",
          name: "Ms. Grace",
          role: "Head of Legal Advisor",
          desc: "She is the guidance on contracts and legal issues to protect HOMDOPT interests and ensure compliance."
        }
      },
      logo: {
        meaning: {
          title: "Logo Meaning",
          desc1: "Homedopt is a combination of Home and Adopt.",
          desc2: "Our mission is to help those in need through our home adoption plan."
        },
        brandmark: {
          title: "Brandmark Anatomy",
          desc: "Our logo consists of two main elements, symbolizing home and hope."
        }
      },
      vision: {
        title: "Our Vision",
        desc: "To become Malaysia's most trusted home adoption platform."
      },
      mission: {
        title: "Our Mission",
        desc: "We are committed to reducing home foreclosures through innovative policies and practices, creating a sustainable future for all."
      },
      values: {
        title: "Our Core Values",
        intro: "We are determined to ensure that all human beings can enjoy prosperous and fulfilling lives and that economic, social and technological progress occurs in harmony.",
        care: {
          title: "We care, we learn and we share",
          desc: "We are passionate about our goal and objective with each other. As we move towards our mission, we are very firm and careful on all calculated risks and as we moving forward, we learn, we grow and we strive for excellence from our experiences."
        },
        right: {
          title: "We do what is right not what is easy",
          desc: "We stand for integrity in everything we do, we held accountable for quality, meaningful and respected service to our Investors, Adopters and Adoptees."
        }
      },
      teamInfo: {
        title: "Management Team Members",
        chartTitle: "Corporate Organisation Chart"  // 更新英文翻译
      },
      companyGroup: {
        title: "Homedopt Group of Companies:",
        companies: [
          {
            name: "Homedopt Sdn Bhd",
            reg: "(1501540-M)"
          },
          {
            name: "Home Prop Management Services Sdn Bhd",
            reg: "(1508203-A)"
          }
        ]
      },
      companyHistory: {
        title: "Our Journey",  // 对应的英文标题:
        paragraphs: [
          "Founded as Malaysia's first home adoption company, <blue>Homedopt Malaysia</blue> redefines property management and ownership with an innovative approach...",
          
          "With the passion of the mission in helping the community fostering sustainable living, revitalize communities, and accessible housing solutions for all. Guided by the core values of innovation, inclusivity, and social responsibility, the business scale were gradually grow bigger that HOMEDOPT RESOURCES was established in 2022 to cater the market needs.",
          
          "By capturing the first developer's projects, a scale of total 77 units properties are being adopted, thus Homedopt Sdn Bhd was registered in 2023 to handle a bigger profile. This wholly experience had made us indeed specialize in securing homes that are going through the foreclosure process. Our team of experienced professionals ensure every adoption is supported with legal, financial, and maintenance guidance, making the process transparent and hassle-free.",
          
          "<blue>Homedopt Malaysia</blue> has bridges the gap between traditional real estate practices and modern needs, empowering adopters to create vibrant neighbourhoods while driving economic growth. <blue>Homedopt Malaysia</blue> is more than a business—it's a movement to redefine homeownership in the 21st century. The 1st ever Home Adoption Platform in Asia!"
        ]
      },
      buttons: {
        readMore: "Read More",
        collapse: "Collapse"
      }
    },
    values: {
      title: "Our Values",
      subtitle: "Professional Service for You",
      proven: "Proven",
      transparent: "Transparent and Trustworthy",
      forOwners: {
        title: "For Property Owners",
        description: "We are committed to understanding your every need and creating the best structure and solutions to ensure your home is preserved and brings maximum benefits to you and your loved ones."
      },
      forClients: {
        title: "For Our Clients",
        description: "We are committed to fostering a collaborative approach to solving your problems while creating an inclusive and healthy work environment where people can learn and achieve more."
      },
      principles: [
        {
          title: "Accountability",
          description: "We connect adopters and adoptees in an effective, efficient, and trustworthy manner."
        },
        {
          title: "Integrity",
          description: "Execute professional, objective, fair, and trustworthy home adoption plans"
        },
        {
          title: "Reliability",
          description: "We provide timely, accurate, and useful clear information with a high sense of responsibility"
        }
      ]
    },
    testimonials: {
      title: "Original Homeowner Stories",
      subtitle: "Real Client Experiences",
      stories: [
        {
          content: "During and after MCO, my business was severely affected. When my friend told me about Homedopt's home adoption plan, I was skeptical at first, but after several rounds of discussions and detailed meetings, I felt very relieved.",
          author: "MR KHOO",
          location: "TAMAN MUHIBBAH, KAPAR, SELANGOR"
        },
        {
          content: "I live with my mother and two siblings in Taman Bukit Ampang. When my mother fell ill, I had to stop working as I needed to care for her at the hospital. This affected my income source, and my housing loan was in arrears for 7 months.",
          author: "KAK ROS",
          location: "TAMAN BUKIT AMPANG, AMPANG"
        },
        {
          content: "4 years ago, I became a guarantor for my cousin's loan at a credit company. Due to his food stall business not doing well, he couldn't repay the loan, so the unpaid loan fell on me.",
          author: "CIK LIZA",
          location: "TAMAN DESA JAYA, KEPONG, KL"
        }
      ]
    },
    stats: {
      experience: "Years Experience",
      adoptions: "Homes Adopted",
      saved: "Homes Saved"
    },
    footer: {
      description: "Since 2016, Homedopt has been Malaysia's first and only home adoption planning platform. We focus on protecting homes undergoing foreclosure procedures.",
      offices: {
        headquarters: "Headquarters Office",
        penang: "Penang Office",
        johor: "Johor Office"
      }
    },
    adoption: {
      hero: {
        title: "Home Adoption",
        subtitle: "We have assisted over 500+ working individuals in successfully achieving",
        highlight: "a stable passive income with zero capital cost",
        marquee: "HOME ADOPTION PLAN • HOME ADOPTION PLAN •"
      },
      cta: {
        title: "Book Free 1-on-1 Consultation Now!",
        benefits: [
          "Down payment, Homedopt got it covered.",
          "Insurance, Homedopt got it covered.",
          "Tenants, Homedopt got it covered.",
          "Just 5 years, and you'll receive rent every month!"
        ],
        note: "Free consultations are available now, click to schedule!",
        button: "Book Free 1-on-1 Consultation Now!"
      },
      scenarios: {
        title: "The 3 most common scenarios in the local real estate market",
        cards: [
          {
            title: "Sell Small House for Bigger House",
            desc: "Having a high income, can sell your small house for to a bigger house."
          },
          {
            title: "Migration to intercity or Oversea",
            desc: "Relocation to a region outside the country or within to study or work"
          },
          {
            title: "Emergency Sell of Assets",
            desc: "Many negative reasons can cause the value of your assets to reduce.",
            reasons: ["No Work", "Commitment", "Gas Price", "Sickness", "Inflation", "Education"]
          }
        ]
      },
      whatIs: {
        title: "What is Home Adoption?",
        subtitle: "Our model is similar to:",
        comparison: [
          "Grab – There are drivers, there are customers",
          "HOMEDOPT – There are House Owners (original owners), there are clients (adopters)"
        ]
      },
      benefits: {
        adopter: {
          title: "ADOPTER",
          subtitle: "For adopters (customers), here are the benefits:",
          items: [
            {
              icon: "💰",
              text: "You can receive adoption fees ranging from RM 500+ to RM 1,500+ per month, similar to rental income!"
            },
            {
              icon: "✨",
              text: "You don't have to pay a substantial amount for stamping fees, legal fees, down payment, or insurance!"
            },
            {
              icon: "⏰",
              text: "You don't need to be tied down for 35 years; instead, you can enjoy steady adoption fees for just 5 years, similar to rental income!"
            }
          ],
          note: "Do you want to know how to receive rental income without buying a property? Click for a free consultation! We will have experts explain it to you!",
          button: "Book Free 1-on-1 Consultation Now!"
        },
        owner: {
          title: "HOUSE OWNER",
          subtitle: "For original homeowners, here are the benefits:",
          items: [
            {
              icon: "💎",
              text: "You can cash out up to 40%!"
            },
            {
              icon: "🌟",
              text: "You can use it for capital turnover with 0% interest over 5 years!"
            },
            {
              icon: "🎯",
              text: "You don't need to make monthly instalments."
            }
          ],
          note: "Do you want to know how to cash out up to 40% without selling your property? Click for a free consultation! We will have experts explain it to you!",
          button: "Book Free 1-on-1 Consultation Now!"
        }
      },
      disclaimer: {
        title: "Financial Disclaimer",
        intro: "The following comprehensive financial disclaimer pertains to the Homedopt Home Adoption Program, which has proven instrumental in aiding over 500 working individuals in achieving \"a stable passive income with zero capital cost.\" It is crucial to gain a thorough understanding of the program, its potential benefits, and the associated financial risks before engaging with the Homedopt Home Adoption Program.",
        blocks: [
          {
            title: "Introduction",
            content: "The Homedopt Home Adoption Program offers a unique opportunity for individuals seeking to generate a passive income without significant upfront capital investment. While the program touts numerous advantages, it is imperative to be fully informed about the financial intricacies and potential risks that come with participating in this initiative."
          },
          {
            title: "Income Guarantees and Investment Considerations",
            content: "One of the primary attractions of the Homedopt Home Adoption Program is the promise of a stable passive income. It is essential to recognize that, despite these assurances, the specific income you may receive is not guaranteed. The level of income can vary significantly due to factors such as market conditions, local rental demand, and individual financial situations. Potential participants should approach this program with a clear understanding that their financial returns may fluctuate over time."
          },
          {
            title: "Investment Risks",
            content: "The Homedopt Home Adoption Program, like all investments, carries inherent risks. It is fundamentally grounded in real estate adoption, where property values can experience both appreciation and depreciation. The return on investment is subject to market fluctuations, making it essential for individuals to assess their risk tolerance and financial objectives before participating. Moreover, unexpected developments, such as economic downturns or property market shifts, can impact investment outcomes."
          },
          {
            title: "Legal and Financial Consultation",
            content: "A critical step before entering the Homedopt Home Adoption Program is to seek legal, financial, and tax advice from qualified professionals. Real estate transactions and income generation can have complex legal and financial implications, varying by jurisdiction. To safeguard your interests, it is highly recommended to have a comprehensive understanding of the program's terms, potential tax liabilities, and the legal framework governing property transactions."
          },
          {
            title: "Results May Vary",
            content: "While success stories from the program's adopters showcase the potential for a stable income, these examples should not be misconstrued as predictive of your specific outcomes. Each participant's financial situation is unique, and various factors, including location, property type, and market dynamics, can influence income levels."
          },
          {
            title: "Past Performance",
            content: "Past performance should not be solely relied upon as a predictor of future results. The property market and economic conditions are subject to change over time, and the performance of investments may differ from historical precedents. As such, individuals should not base their expectations solely on past performance data."
          },
          {
            title: "Homedopt Home Adoption Program's Role",
            content: "The Homedopt Home Adoption Program distinguishes itself by covering down payments, insurance, and various other costs associated with property adoption. It is imperative to understand that this financial assistance does not eliminate investment risks. Participants should conduct extensive due diligence, become familiar with the terms and conditions of the program, and be aware of potential limitations and responsibilities."
          },
          {
            title: "Individual Financial Situation",
            content: "Participants in the Homedopt Home Adoption Program must consider their unique financial situation, goals, and risk tolerance before embarking on this journey. Personal financial well-being should be the foremost concern. The program's suitability may differ depending on an individual's objectives and circumstances, making it imperative to tailor investment decisions to your specific needs."
          },
          {
            title: "Acknowledgment and Caution",
            content: "By engaging with the information and services provided by the Homedopt Home Adoption Program, you acknowledge that you fully understand the associated risks with real estate adoption and financial decisions. It is your responsibility to make well-informed choices, seek professional advice when necessary, and carefully weigh the potential benefits against the inherent risks. The Homedopt Home Adoption Program disclaims any responsibility for the financial outcomes of your investments. It is advisable to approach this opportunity with prudence, foresight, and a commitment to a well-researched decision-making process."
          }
        ]
      }
    },
    asset: {
      hero: {
        title: "House Reversion Plan",
        subtitle: "Let Your Property Work for You with Reverse Mortgage!",
        button: "Learn More"
      },
      intro: {
        title: "About House Reversion Plan",
        desc1: "Homedopt is a platform born specifically to solve asset and cash flow problems",
        desc2: "Our House Reversion Plan provides homeowners with stable legal income. With up to 6% annual legal income, transform your property into reliable retirement funds",
        desc3: "We provide professional support and stable returns, ensuring your assets are secure through strict internal controls",
        benefits: {
          income: {
            title: "Stable Monthly Income",
            desc: "Receive fixed pension payments monthly"
          },
          ownership: {
            title: "Retain Property",
            desc: "Continue to own your property rights"
          }
        }
      },
      example: {
        title: "EXAMPLE OF HOUSE REVERSION PLAN",
        items: {
          propertyValue: {
            title: "PROPERTY VALUE",
            value: "RM500,000"
          },
          monthlyReceive: {
            title: "RECEIVE / MONTH",
            value: "RM2,662.50"
          },
          yearlyReceive: {
            title: "RECEIVE / YEAR",
            value: "RM31,950.00"
          },
          total: {
            title: "TOTAL",
            value: "RM159,750"
          }
        }
      },
      faq: {
        title: "Frequently Asked Questions",
        items: [
          {
            question: "Why switch from relying on children to reverse mortgage?",
            answer: "With changes in social structure and family relationships, more people face living alone or away from their children. Therefore, the reverse mortgage plan provides an option for those who own property but want additional income after retirement."
          },
          {
            question: "What are the benefits of House Reversion?",
            answer: "The House Reversion Plan can provide you with a stable additional source of income without selling your property. You can receive monthly dividends while retaining property ownership, providing financial support for your retirement life."
          },
          {
            question: "How much can I receive annually?",
            answer: "The amount you can receive annually depends on your property value, age, and other factors. Generally, you can expect to receive additional income equivalent to a certain percentage of your property value, typically between 3% and 6%."
          },
          {
            question: "Do I need full ownership to participate?",
            answer: "Yes, you need to have full ownership to participate in the reverse mortgage plan."
          },
          {
            question: "Will the property still be my inheritance?",
            answer: "Yes, the property remains your inheritance. The reverse mortgage plan does not affect your property ownership. Your property can be passed on to your family or beneficiaries as your inheritance."
          },
          {
            question: "How will my property value be determined?",
            answer: "Your property value will be assessed by professional appraisers to ensure fairness and accuracy."
          },
          {
            question: "Do I need to pay any fees to participate in this plan?",
            answer: "Participation in the plan requires management fees. Please consult our staff for accurate information about specific situations."
          }
        ]
      }
    },
    mortgage: {
      hero: {
        title: "Mortgage Relief Solution",
        subtitle: "We offer a 5-year mortgage relief solution!",
        desc: "Free yourself from mortgage payments for the next five years",
        button: "Learn More"
      },
      intro: {
        title: "Economic Downturn & Mortgage Pressure?",
        highlight: "Up to 5 Years Mortgage-Free",
        desc1: "Homedopt is a platform created specifically to solve asset and cash flow problems",
        desc2: "Our plan aims to help you deal with mortgage pressure challenges, relieving you from mortgage pressure for a period of time to reduce your financial burden",
        desc3: "We provide additional liquidity support to help you cope with other living expenses"
      },
      faq: {
        title: "Frequently Asked Questions",
        items: [
          {
            question: "Feeling the pressure of mortgage payments during economic downturn?",
            answer: "We understand that the current economic environment may put pressure on your mortgage payments. Our plan aims to help you cope with this financial difficulty by relieving you from mortgage pressure for a period of time to reduce your financial burden."
          },
          {
            question: "How to be mortgage-free for five years?",
            answer: "By participating in our plan, you can include your property in the program, making it unnecessary for you to pay mortgage for the next five years, while we provide additional liquidity support to help you cope with other extra living expenses."
          },
          {
            question: "Will I still be the owner of the house?",
            answer: "Yes, you remain the owner of the property. This plan is designed to provide you with financial support to deal with mortgage pressure during a specific period, but you still retain ownership and control of the property."
          },
          {
            question: "What documents do I need to provide to participate in the plan?",
            answer: "Please prepare property ownership certificates and loan contracts related to your property. Specific requirements will depend on your situation."
          },
          {
            question: "Can I choose the mortgage-free period?",
            answer: "Currently, our plan offers up to five years of mortgage-free period. If you have special needs, please contact our staff."
          },
          {
            question: "Can I end my participation in the plan early?",
            answer: "Yes, the company has specific regulations. Please contact our staff for detailed information."
          }
        ]
      }
    },
    advance: {
      hero: {
        title: "Asset Pre-payment Plan",
        subtitle: "Break Through Financial Bottlenecks, Control Your Life Freely",
        button: "Learn More"
      },
      intro: {
        title: "About Asset Pre-payment Plan",
        desc1: "Homedopt is a platform created specifically to solve asset and cash flow problems",
        desc2: "Our plan aims to help you deal with illiquid assets, allowing you to access 1/3 of your funds early for cash flow",
        desc3: "We provide additional liquidity support to help you cope with other living expenses"
      },
      faq: {
        title: "Frequently Asked Questions",
        items: [
          {
            question: "What are my benefits?",
            answer: "You can receive 1/3 of your property value as funds without having to sell your property urgently. The property value will be assessed by professional appraisers."
          },
          {
            question: "When can I receive these funds?",
            answer: "Once you agree to our plan and sign the relevant documents, the transaction can be completed in as fast as two weeks, and you can receive the funds."
          },
          {
            question: "Do I need to repay these funds?",
            answer: "Yes, these funds need to be repaid after 5 years, including property management fees. Don't worry, you can still retain the right to use your property during this period."
          },
          {
            question: "Is this plan applicable to all types of properties?",
            answer: "This plan is applicable to residential properties, including detached houses, apartments, and townhouses. For specific situations, we recommend contacting our consultants for more details."
          },
          {
            question: "Can these funds be used for any purpose?",
            answer: "Yes, you can freely use these funds for any purpose you need, whether it's paying bills, making investments, or other emergency expenses."
          },
          {
            question: "Can I repay these funds early?",
            answer: "Yes, you can choose to repay these funds early, with certain early repayment management fees during this period. Please understand the relevant terms in detail before signing the contract."
          }
        ]
      }
    },
    rental: {
      hero: {
        title: "Rental Rebate Program (RRP)",
        subtitle: "Exclusive Opportunity Tailored for Tenants"
      },
      features: {
        card1: {
          title: "Save Homes",
          description: "A program specially designed for tenants, offering unique opportunities to create passive income."
        },
        card2: {
          title: "Participate in Rescue",
          description: "Participate in saving houses from auction while earning considerable returns."
        },
        card3: {
          title: "Financial Portfolio",
          description: "Build your financial portfolio through this innovative program to achieve good financial scores for future lending."
        }
      },
      comparison: {
        title: "What's the differences between",
        subtitle: "Normal Rental vs Rental Rebate Program (RRP)",
        normal: "Normal Rental",
        rrp: "Rental Rebate Program (RRP)",
        description: "While normal rental involves tenants paying full rent directly to landlords, unexpected issues can arise such as low market rates, late payments, or vacancies. HOMEDOPT's Rental Rebate Program offers additional financial assistance or better rental solutions for qualified individuals or families facing economic difficulties."
      },
      conclusion: {
        title: "HOMEDOPT is here to help!",
        description: "Yes, Rental Rebate Program can indeed be very helpful for tenants and offer an innovative solution to reduce rental costs effectively."
      }
    },
    contact: {
      hero: {
        title: "Contact Us",
        subtitle: "We are ready to provide you with professional consultation services"
      },
      info: {
        office: {
          title: "Headquarters Office",
          address: "G-27, G Floor, The Heritage, Jalan SB Dagang, Mines Wellness City, 43300 Seri Kembangan, Selangor."
        },
        phone: {
          title: "Contact Number",
          number: "+60172013168",
          whatsapp: "Click to contact us via WhatsApp"
        },
        email: {
          title: "Email",
          address: "liewsylvia216@gmail.com"
        }
      },
      form: {
        title: "Send Us a Message",
        name: "Your Name",
        email: "Email",
        phone: "Phone Number",
        subject: "Subject",
        message: "Please enter your message",
        submit: "Send Message"
      },
      map: {
        title: "Our Location"
      }
    },
    career: {
      hero: {
        title: "We are hiring, join our team of talented individuals",
        position: "Admin Assistant"
      },
      sections: {
        requirements: {
          title: "Requirements",
          items: [
            "Candidates without experience are encouraged to apply, training will be provided",
            "Candidates at least SPM and above",
            "MANDARIN speaking proficiency is preferred",
            "Must be willing to work in The Heritage, Seri Kembangan",
            "Required language(s): Bahasa Malaysia, Mandarin, English",
            "Computer skills – using appropriate computer skills and knowledge, ranging from data entry to systems analysis",
            "Team Skills – being able to work collaboratively with others in a participative management environment",
            "Excellent knowledge of MS Office",
            "Full comprehension of office management systems and procedures"
          ]
        },
        responsibilities: {
          title: "Responsibilities",
          items: [
            "Act as the point of contact between the executives and internal / external clients",
            "Undertake the tasks of receiving calls, take messages and routing correspondence",
            "Handle requests and queries appropriately",
            "Take dictation and minutes and accurately enter data",
            "Monitor office supplies",
            "Develop and carry out an efficient documentation and filing system",
            "Organizes workload, sets priorities and works within deadlines"
          ]
        },
        benefits: {
          title: "Benefits",
          items: [
            "Medical and hospitalisation leave",
            "Statutory deductions (EPF, SOCSO)",
            "Additional commission",
            "Allowance provided",
            "Career advancement",
            "Bonus",
            "Team building",
            "Company trip"
          ]
        },
        additionalBenefits: {
          title: "Additional Benefits",
          items: [
            "Annual Leave",
            "EPF / SOCSO / PCB",
            "Performance Bonus"
          ]
        }
      },
      form: {
        title: "Apply Now",
        fullName: "Full Name",
        phone: "Phone Number",
        email: "Email",
        nationality: "Nationality",
        employmentStatus: {
          label: "Employment Status",
          options: {
            select: "Select status",
            experienced: "I have work experience",
            fresh: "Fresh graduate"
          }
        },
        fieldOfStudy: "Field of Study",
        expectedSalary: "Expected Salary (RM)",
        reference: {
          label: "How did you hear about us?",
          options: {
            select: "Select option",
            website: "Company Website",
            social: "Social Media",
            referral: "Referral",
            other: "Other"
          }
        },
        resume: {
          label: "Upload Resume (Optional)",
          hint: "Optional, you can also send your resume via email later"
        },
        portfolio: {
          label: "Upload Portfolio (Optional)",
          hint: "Optional, you can also send your portfolio via email later"
        },
        submit: "Submit Application",
        successMessage: "Please attach your resume and portfolio (if any) in the opened email client before sending."
      }
    },
    faq: {
      hero: {
        title: "Frequently Asked Questions",
        subtitle: "Find all the answers you need"
      },
      search: {
        placeholder: "Search questions...",
        noResults: "No questions found"
      },
      categories: {
        general: "General",
        adoption: "Home Adoption",
        asset: "House Reversion",
        mortgage: "Mortgage Relief",
        advance: "Asset Pre-payment",
        rental: "Rental Rebate"
      },
      chat: {
        title: "Have More Questions?",
        description: "Our professional consultants are here to help",
        button: "Consult Now",
        floating: "Online Chat",
        openChat: "AI Assistant",
        welcome: "Hello! I'm HOMEDOPT's AI Assistant. How may I help you today?",
        inputPlaceholder: "Type your question..."
      },
      // 添加问题列表
      items: [
        {
          category: 'general',
          question: 'What is HOMEDOPT?',
          answer: 'HOMEDOPT is Malaysia\'s first home adoption platform, focusing on providing professional property protection solutions for homeowners facing mortgage pressure.'
        },
        {
          category: 'adoption',
          question: 'How does the Home Adoption Plan work?',
          answer: 'The Home Adoption Plan matches homeowners with adopters to provide a win-win solution.'
        },
        {
          category: 'asset',
          question: 'What are the benefits of the House Reversion Plan?',  // 更新
          answer: 'The House Reversion Plan allows property owners to receive financial support without selling their property, with up to 6% legal annual income.'  // 更新
        },
        {
          category: 'mortgage',
          question: 'How can I apply for the Mortgage Relief Plan?',
          answer: 'You can apply for the Mortgage Relief Plan through our website or by contacting our consultants.'
        },
        {
          category: 'advance',
          question: 'What are the requirements for the Asset Pre-payment Plan?',
          answer: 'Requirements include property certificate over 2 years, no mortgage, clear ownership, etc.'
        },
        {
          category: 'rental',
          question: 'How does the Rental Rebate Plan help tenants?',
          answer: 'The Rental Rebate Plan provides tenants with rental benefits and opportunities to participate in property appreciation.'
        }
      ]
    },
    login: {
      title: "Welcome Back",
      subtitle: "Please login to your account",
      ic: "IC Number",
      icPlaceholder: "Enter your IC number",
      password: "Password",
      passwordPlaceholder: "Enter your password",
      rememberMe: "Remember me",
      forgotPassword: "Forgot password?",
      loginButton: "Login",
      errors: {
        invalidCredentials: "Invalid IC number or password",
        serverError: "Server error, please try again later",
        loginFailed: "Login failed, please try again"
      }
    },
    profile: {
      title: "Profile",
      labels: {
        name: "Username",
        email: "Email",
        role: "Role"
      },
      roles: {
        admin: "Administrator",
        boss: "Boss",
        manager: "Manager",
        agent: "Agent"
      },
      downlines: {
        title: "Team Members",
        loading: "Loading...",
        error: "Failed to fetch team members"
      },
      buttons: {
        changePassword: "Change Password"
      },
      passwordModal: {
        title: "Change Password",
        newPassword: "New Password",
        confirmPassword: "Confirm Password",
        submit: "Confirm",
        cancel: "Cancel",
        success: "Password updated successfully",
        errors: {
          mismatch: "Passwords do not match",
          updateFailed: "Failed to update password"
        }
      }
    },
    userManagement: {
      title: "User Management",
      loading: "Loading...",
      table: {
        name: "Username",
        email: "Email",
        role: "Role",
        actions: "Actions",
        upline: "Upline",
        noUpline: "No Upline",
        newPassword: "New Password",
        confirmPassword: "Confirm Password",
        accountNo: "Bank Account",  // 添加银行账号英文翻译
        ic: "IC Number"  // 添加 IC 英文翻译
      },
      roles: {
        admin: "Administrator",
        boss: "Boss",
        manager: "Manager",
        leader: "Team Leader",  // 添加英文翻译
        agent: "Agent"
      },
      buttons: {
        add: "Add User",
        edit: "Edit",
        save: "Save",
        cancel: "Cancel",
        delete: "Delete",
        create: "Create"
      },
      placeholders: {
        name: "Enter username",
        email: "Enter email",
        password: "Enter password",
        newPassword: "New password (optional)",
        accountNo: "Enter bank account number",  // 添加银行账号占位符英文翻译
        ic: "Enter IC number"  // 添加 IC 占位符英文翻译
      },
      messages: {
        confirmDelete: "Are you sure you want to delete this user?",
        createSuccess: "User created successfully",
        updateSuccess: "User updated successfully",
        deleteSuccess: "User deleted successfully"
      },
      errors: {
        fetchError: "Failed to fetch user list",
        createError: "Failed to create user",
        updateError: "Failed to update user",
        deleteError: "Failed to delete user",
        emailExists: "Email already exists",
        nameExists: "Username already exists",
        accessDenied: "Access denied"
      },
      search: {
        placeholder: "搜索用户名、邮箱、IC或银行账号..."
      },
      sort: {
        byRole: "职位排序",  // 简化翻译
        byTimeAsc: "较早创建",
        byTimeDesc: "较新创建"
      }
    },
    teamInfo: {  // 原来是 team
      title: "我们的团队",
      description: "专业且富有经验的团队",
      // ... 其他团队相关翻译
    },
    teamMembers: {  // 原来是 team
      title: "团队成员",
      members: [
        // ... 团队成员列表
      ]
    }
  }
}; 