import React, { useState, useRef, useEffect } from 'react';
import './FAQ.css';
import { translations } from '../locales/translations';
import mascot from '../images/mascot.png';

function FAQ({ language = 'en' }) {
  const [activeCategory, setActiveCategory] = useState('general');
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  
  const messagesEndRef = useRef(null);
  
  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  
  const t = translations[language]?.faq;

  if (!t) return null;

  // FAQ分类
  const categories = [
    { id: 'general', name: language === 'cn' ? '一般问题' : 'General Questions' },
    { id: 'adoption', name: language === 'cn' ? '房屋领养' : 'Home Adoption' },
    { id: 'asset', name: language === 'cn' ? '资产回馈' : 'Asset Return' },
    { id: 'mortgage', name: language === 'cn' ? '房贷纾困' : 'Mortgage Relief' },
    { id: 'advance', name: language === 'cn' ? '资产预付' : 'Asset Advance' },
    { id: 'rental', name: language === 'cn' ? '租金回扣' : 'Rental Rebate' }
  ];

  // 处理分类点击
  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
    
    // 根据语言和分类生成默认问题
    const defaultQuestions = {
      cn: {
        'general': '什么是HOMEDOPT?',
        'adoption': '房屋领养计划是如何运作的？',
        'asset': '资产回馈计划有什么好处？',
        'mortgage': '如何申请房贷纾困方案？',
        'advance': '资产预付计划的申请条件是什么？',
        'rental': '租金回扣计划如何帮助租户？'
      },
      en: {
        'general': 'What is HOMEDOPT?',
        'adoption': 'How does the Home Adoption Plan work?',
        'asset': 'What are the benefits of the Asset Return Plan?',
        'mortgage': 'How can I apply for the Mortgage Relief Plan?',
        'advance': 'What are the requirements for the Asset Advance Plan?',
        'rental': 'How does the Rental Rebate Plan help tenants?'
      }
    };

    // 根据当前语言设置输入框的值
    setInputMessage(defaultQuestions[language][categoryId] || '');
  };

  // 获取建议问题
  const getSuggestedQuestions = () => {
    return t.items.filter(item => item.category === activeCategory).slice(0, 4);
  };

  // 定义知识库
  const aiKnowledgeBase = {
    cn: {
      'HOMEDOPT': {
        what: 'HOMEDOPT是马来西亚首创的房屋领养平台，成立于2016年。我们专注于为面临房贷压力的业主提供专业的房产保障解决方案。',
        mission: '我们的使命是通过创新的政策和实践来减少房屋拍卖，为所有人创造可持续的未来。',
        experience: '我们已经帮助超过250个家庭成功解决房产问题，拥有7年以上的专业经验。'
      },
      '房屋领养': {
        what: '房屋领养计划是HOMEDOPT的核心服务，通过匹配房主和领养者，为双方提供互惠互利的解决方案。',
        process: [
          '提交申请和房产资料',
          '专业团队评估房产',
          '制定个性化方案',
          '签署合法协议',
          '开始执行计划'
        ],
        requirements: [
          '房产产权清晰',
          '无重大债务纠纷',
          '房屋结构完好',
          '位于计划覆盖区域内'
        ],
        benefits: {
          owner: [
            '获得稳定现金',
            '保留房产所有权',
            '专业团队管理',
            '零成本维护'
          ],
          adopter: [
            '低风险投资机会',
            '稳定月收入',
            '专业支持服务',
            '灵活退出机制'
          ]
        }
      },
      '资产回馈': {
        what: '资产回馈计划让房主无需出售房产就能获得资金支持。',
        features: '每年高达6%合法收入，将房产转化为可靠的养老资金。',
        process: [
          '提交房产评估申请',
          '专业评估团队实地考察',
          '制定个性化回馈方案',
          '签署协议',
          '按月收取回馈金'
        ],
        requirements: [
          '房产产权清晰完整',
          '房屋状况良好',
          '位于计划覆盖区域内',
          '无重大债务纠纷'
        ],
        benefits: [
          '保留房产所有权和使用权',
          '获得稳定月收入',
          '专业团队管理维护',
          '合法合规操作',
          '灵活的退出机制',
          '资金用途不受限制'
        ]
      },
      '房贷纾困': {
        what: '为面临房贷压力的业主提供专业解决方案。',
        features: '提供长达5年的房贷减免期，专业团队全程协助处理相关手续。',
        process: [
          '提交房贷困难说明',
          '提供相关财务证明',
          '专业团队评估情况',
          '制定纾困方案',
          '协助办理相关手续'
        ],
        requirements: [
          '房贷逾期不超过6个月',
          '有稳定收入来源',
          '愿意积极配合还款',
          '提供必要的财务证明'
        ],
        benefits: [
          '减轻房贷压力',
          '避免房产拍卖',
          '保留居住权',
          '灵活还款方案',
          '专业团队指导',
          '信用记录保护'
        ]
      },
      '资产预付': {
        what: '让房主提前获得房产价值的1/3作为资金周转。',
        features: '5年期限，期间保留房产使用权。',
        process: [
          '提交资产预付申请',
          '房产价值评估',
          '制定预付方案',
          '签署合同',
          '资金到账'
        ],
        requirements: [
          '房产证满2年',
          '房产无抵押',
          '产权清晰',
          '房屋状况良好',
          '位于计划覆盖区域'
        ],
        benefits: [
          '快速获取资金',
          '保留产权和使用权',
          '还款方式灵活',
          '无需提供收入证明',
          '手续简便快捷',
          '资金用途不受限'
        ]
      },
      '租金回扣': {
        what: '为租户提供创新的租金支付方案。',
        features: '租金回扣优惠，同时享受房产增值收益。',
        process: [
          '提交租房需求',
          '选择合适房源',
          '签署租赁协议',
          '支付租金',
          '获取回扣收益'
        ],
        requirements: [
          '稳定工作或收入来源',
          '良好的信用记录',
          '提供基本身份证明',
          '同意签署标准租约'
        ],
        benefits: [
          '租金优惠回扣',
          '享受房产增值',
          '灵活付款方式',
          '专业租务管理',
          '优先购房权',
          '租约更有保障'
        ]
      }
    },
    en: {
      'HOMEDOPT': {
        what: 'HOMEDOPT is Malaysia\'s first home adoption platform, established in 2016. We focus on providing professional property protection solutions for homeowners facing mortgage pressure.',
        mission: 'Our mission is to reduce home foreclosures through innovative policies and practices, creating a sustainable future for all.',
        experience: 'We have successfully helped over 250 families and have more than 7 years of professional experience.'
      },
      'Home Adoption': {
        what: 'The Home Adoption Plan is HOMEDOPT\'s core service, matching homeowners with adopters to provide a win-win solution.',
        process: [
          'Submit application and property documents',
          'Professional team evaluation',
          'Customize personalized plan',
          'Sign legal agreement',
          'Start plan execution'
        ],
        requirements: [
          'Clear property ownership',
          'No major debt disputes',
          'Good property condition',
          'Location within coverage area'
        ],
        benefits: {
          owner: [
            'Stable cash flow',
            'Retain property ownership',
            'Professional team management',
            'Zero maintenance cost'
          ],
          adopter: [
            'Low-risk investment opportunity',
            'Stable monthly income',
            'Professional support service',
            'Flexible exit mechanism'
          ]
        }
      },
      'Asset Return': {
        what: 'The Asset Return Plan allows property owners to receive financial support without selling their property.',
        features: 'Up to 6% legal annual income, converting property into reliable retirement funds.',
        process: [
          'Submit property evaluation application',
          'Professional team site inspection',
          'Customize return plan',
          'Sign agreement',
          'Receive monthly returns'
        ],
        requirements: [
          'Clear property ownership',
          'Good property condition',
          'Location within coverage area',
          'No major debt disputes'
        ],
        benefits: [
          'Retain property ownership and usage rights',
          'Receive stable monthly income',
          'Professional team management',
          'Legal and compliant operation',
          'Flexible exit mechanism',
          'Unrestricted use of funds'
        ]
      },
      'Mortgage Relief': {
        what: 'Professional solutions for homeowners facing mortgage pressure.',
        features: 'Provides up to 5 years of mortgage relief period with professional team assistance.',
        process: [
          'Submit mortgage difficulty statement',
          'Provide financial documentation',
          'Professional team evaluation',
          'Design relief plan',
          'Assist with procedures'
        ],
        requirements: [
          'Mortgage overdue less than 6 months',
          'Stable income source',
          'Willing to cooperate with repayment',
          'Provide necessary financial proof'
        ],
        benefits: [
          'Reduce mortgage pressure',
          'Avoid property auction',
          'Retain residency rights',
          'Flexible repayment options',
          'Professional guidance',
          'Credit record protection'
        ]
      },
      'Asset Advance': {
        what: 'Allows homeowners to receive 1/3 of property value in advance for financial turnover.',
        features: '5-year term with retained property usage rights.',
        process: [
          'Submit advance application',
          'Property value assessment',
          'Design advance plan',
          'Sign contract',
          'Fund disbursement'
        ],
        requirements: [
          'Property certificate over 2 years',
          'No mortgage',
          'Clear ownership',
          'Good property condition',
          'Location within coverage area'
        ],
        benefits: [
          'Quick access to funds',
          'Retain ownership and usage rights',
          'Flexible repayment methods',
          'No income proof required',
          'Simple procedures',
          'Unrestricted fund usage'
        ]
      },
      'Rental Rebate': {
        what: 'Provides innovative rental payment solutions for tenants.',
        features: 'Rental rebate benefits while participating in property appreciation.',
        process: [
          'Submit rental needs',
          'Select suitable property',
          'Sign lease agreement',
          'Pay rent',
          'Receive rebate benefits'
        ],
        requirements: [
          'Stable job or income source',
          'Good credit record',
          'Provide basic identification',
          'Agree to standard lease'
        ],
        benefits: [
          'Rental rebate benefits',
          'Property appreciation participation',
          'Flexible payment options',
          'Professional rental management',
          'Priority purchase rights',
          'Secured lease terms'
        ]
      }
    }
  };

  // 改进的AI回答函数
  const getAIResponse = async (question) => {
    const lowerQuestion = question.toLowerCase();
    let response = '';
    
    // 根据当前语言选择知识库
    const knowledge = aiKnowledgeBase[language];
    
    // 检查是否是关于具体服务的问题
    const services = language === 'cn' ? {
      '房屋领养': ['领养', '房屋领养', '领养计划'],
      '资产回馈': ['回馈', '资产回馈', '养老金'],
      '房贷纾困': ['房贷', '纾困', '还款'],
      '资产预付': ['预付', '资产预付', '周转'],
      '租金回扣': ['租金', '回扣', '租房']
    } : {
      'Home Adoption': ['adoption', 'adopt', 'home'],
      'Asset Return': ['return', 'asset return', 'pension'],
      'Mortgage Relief': ['mortgage', 'relief', 'repayment'],
      'Asset Advance': ['advance', 'asset advance', 'turnover'],
      'Rental Rebate': ['rental', 'rebate', 'rent']
    };

    // 识别问题类型
    const questionTypes = language === 'cn' ? {
      process: ['流程', '步骤', '怎么做', '如何'],
      requirements: ['要求', '条件', '资格', '标准'],
      benefits: ['好处', '优势', '收益', '利益'],
      what: ['什么是', '介绍', '说明']
    } : {
      process: ['process', 'steps', 'how', 'procedure', 'work'],
      requirements: ['requirements', 'conditions', 'qualifications', 'criteria', 'need'],
      benefits: ['benefits', 'advantages', 'returns', 'profits', 'good'],
      what: ['what is', 'introduction', 'about', 'tell me']
    };

    // 匹配服务类型
    let matchedService = null;
    for (const [service, keywords] of Object.entries(services)) {
      if (keywords.some(keyword => lowerQuestion.includes(keyword.toLowerCase()))) {
        matchedService = service;
        break;
      }
    }

    // 处理关于HOMEDOPT的一般问题
    if (lowerQuestion.includes('homedopt') || lowerQuestion.includes('company')) {
      const info = knowledge.HOMEDOPT;
      response = `${info.what}\n\n${info.mission}\n\n${info.experience}`;
    }
    // 处理具体服务的问题
    else if (matchedService && knowledge[matchedService]) {
      const serviceInfo = knowledge[matchedService];
      
      // 匹配问题类型并生成回答
      if (questionTypes.process.some(type => lowerQuestion.includes(type.toLowerCase())) && serviceInfo.process) {
        const prefix = language === 'cn' ? `${matchedService}的申请流程：` : `${matchedService} Application Process:`;
        response = `${prefix}\n\n` + 
                   serviceInfo.process.map((step, index) => `${index + 1}. ${step}`).join('\n');
      }
      else if (questionTypes.requirements.some(type => lowerQuestion.includes(type.toLowerCase())) && serviceInfo.requirements) {
        const prefix = language === 'cn' ? `${matchedService}的申请要求：` : `${matchedService} Requirements:`;
        response = `${prefix}\n\n` + 
                   serviceInfo.requirements.map(req => `• ${req}`).join('\n');
      }
      else if (questionTypes.benefits.some(type => lowerQuestion.includes(type.toLowerCase())) && serviceInfo.benefits) {
        if (typeof serviceInfo.benefits === 'object' && serviceInfo.benefits.owner && serviceInfo.benefits.adopter) {
          const ownerPrefix = language === 'cn' ? '对房主：' : 'For Property Owners:';
          const adopterPrefix = language === 'cn' ? '对领养者：' : 'For Adopters:';
          response = `${matchedService} ${language === 'cn' ? '的好处' : 'Benefits'}:\n\n${ownerPrefix}\n` +
                    serviceInfo.benefits.owner.map(benefit => `• ${benefit}`).join('\n') +
                    '\n\n' + adopterPrefix + '\n' +
                    serviceInfo.benefits.adopter.map(benefit => `• ${benefit}`).join('\n');
        } else if (Array.isArray(serviceInfo.benefits)) {
          const prefix = language === 'cn' ? `${matchedService}的好处：` : `${matchedService} Benefits:`;
          response = `${prefix}\n\n` +
                    serviceInfo.benefits.map(benefit => `• ${benefit}`).join('\n');
        }
      }
      else {
        // 默认返回服务介绍
        response = serviceInfo.what;
        if (serviceInfo.features) {
          response += '\n\n' + serviceInfo.features;
        }
      }
    }
    // 默认回答
    else {
      response = language === 'cn' ? 
        "您好！我是HOMEDOPT的AI助手。我们提供以下服务：\n\n" +
        "1. 房屋领养计划\n2. 资产回馈计划\n3. 房贷纾困方案\n" +
        "4. 资产预付计划\n5. 租金回扣计划\n\n" +
        "您想了解哪个具体服务呢？" :
        "Hello! I'm HOMEDOPT's AI assistant. Here are our services:\n\n" +
        "1. Home Adoption Plan\n2. Asset Return Plan\n3. Mortgage Relief Plan\n" +
        "4. Asset Advance Plan\n5. Rental Rebate Plan\n\n" +
        "Which service would you like to learn more about?";

      // 添加语言相关的建议问题
      const suggestions = language === 'cn' ? 
        "\n\n您可能还想了解：\n• 申请流程是怎样的？\n• 需要准备哪些材料？\n• 可以获得哪些好处？" :
        "\n\nYou might also want to know:\n• What is the application process?\n• What documents are required?\n• What are the benefits?";

      response += suggestions;
    }

    return response;
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    // 添加用户消息
    const userMessage = {
      text: inputMessage,
      isUser: true,
      timestamp: new Date().toLocaleTimeString()
    };
    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');

    // 添加AI正在输入的提示
    const loadingMessage = {
      text: '...',
      isUser: false,
      isLoading: true,
      timestamp: new Date().toLocaleTimeString()
    };
    setMessages(prev => [...prev, loadingMessage]);

    // 获取AI回答
    const aiResponse = await getAIResponse(inputMessage);
    
    // 移除加载消息并添加AI回答
    setMessages(prev => [
      ...prev.filter(msg => !msg.isLoading),
      {
        text: aiResponse,
        isUser: false,
        timestamp: new Date().toLocaleTimeString()
      }
    ]);
  };

  // 滚动到底部的函数
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="faq-page">
      {/* 左侧边栏 */}
      <div className={`faq-sidebar ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
        <button 
          className="category-toggle"
          onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
        >
          {language === 'cn' ? '常见问题分类' : 'FAQ Categories'}
          <span className={`category-toggle-icon ${isSidebarExpanded ? 'active' : ''}`}>
            ▼
          </span>
        </button>
        <div className="category-list">
          {categories.map(category => (
            <button
              key={category.id}
              className={`category-item ${activeCategory === category.id ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category.id)}
            >
              {category.name}
            </button>
          ))}
        </div>
      </div>

      {/* 主聊天区域 */}
      <div className="chat-main">
        {/* 聊天消息区域 */}
        <div className="chat-messages">
          {/* 欢迎消息 */}
          <div className="message-group">
            <div className="message ai">
              <img src={mascot} alt="AI" className="message-avatar" />
              <div className="message-content">
                <div className="message-text">
                  {t.chat.welcome}
                </div>
                {/* 使用建议问题 */}
                <div className="quick-questions">
                  {getSuggestedQuestions().map((faq, index) => (
                    <button 
                      key={index}
                      className="quick-question-btn"
                      onClick={() => setInputMessage(faq.question)}
                    >
                      {faq.question}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {messages.map((message, index) => (
            <div key={index} className="message-group">
              <div className={`message ${message.isUser ? 'user' : 'ai'}`}>
                {!message.isUser && <img src={mascot} alt="AI" className="message-avatar" />}
                <div className="message-content">
                  {message.isLoading ? (
                    <div className="typing-indicator">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  ) : (
                    <div className="message-text">{message.text}</div>
                  )}
                </div>
              </div>
            </div>
          ))}
          
          {/* 滚动目标div */}
          <div ref={messagesEndRef} />
        </div>

        {/* 底部输入框 */}
        <div className="chat-footer">
          <form className="chat-input" onSubmit={handleSendMessage}>
            <input
              type="text"
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              placeholder={t.chat.inputPlaceholder}
            />
            <button type="submit" className="send-button">
              <span className="send-icon">↑</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default FAQ; 