import React from 'react';
import './SuccessModal.css';

function SuccessModal({ isOpen, message, onClose }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="success-icon">✓</div>
                <h2>{message}</h2>
                <button className="modal-button" onClick={onClose}>确定</button>
            </div>
        </div>
    );
}

export default SuccessModal; 