import React from 'react';
import './Advance.css';
import { translations } from '../locales/translations';

function Advance({ language }) {
  const t = translations[language];
  
  console.log('Current language:', language);
  console.log('Translations:', t?.advance);

  const handlePdfClick = () => {
    window.open('/pdf/A4-ASSET-PRE-PAYMENT-HOMEDOPT.pdf', '_blank');
  };

  // FAQ数据
  const faqs = [
    {
      question: t?.advance?.faq?.items[0]?.question || "我的好处是什么？",
      answer: t?.advance?.faq?.items[0]?.answer || "您无需急着卖掉房产也可以获得您房产价值的1/3作为资金,房产的价值将由专业评估师进行评估"
    },
    {
      question: t?.advance?.faq?.items[1]?.question || "我何时可以拿到这笔资金",
      answer: t?.advance?.faq?.items[1]?.answer || "一旦您同意了我们的方案并签署了相关文件,最快可以在两个星期内完成交易,您就可以拿到资金了。"
    },
    {
      question: t?.advance?.faq?.items[2]?.question || "我需要偿还这笔资金吗？",
      answer: t?.advance?.faq?.items[2]?.answer || "是的,这笔资金需要在5年后偿还,这包括了房产管理费. 无需担心, 期间仍然可以保留您的房产使用权."
    },
    {
      question: t?.advance?.faq?.items[3]?.question || "这个方案适用于所有类型的房产吗？",
      answer: t?.advance?.faq?.items[3]?.answer || "这个方案适用于住宅房产, 包括独立屋、公寓和排屋.具体情况建议您与我们的顾问联系以获取更多详细信息."
    },
    {
      question: t?.advance?.faq?.items[4]?.question || "这笔资金可以用于任何用途吗？",
      answer: t?.advance?.faq?.items[4]?.answer || "是的, 您可以自由支配这笔资金, 用于任何您需要的用途, 无论是支付账单、进行投资或是其他紧急支出"
    },
    {
      question: t?.advance?.faq?.items[5]?.question || "我可以提前偿还这笔资金吗？",
      answer: t?.advance?.faq?.items[5]?.answer || "是的, 您可以选择提前偿还这笔资金,在这期间需要支付一定的提前偿还管理费用. 请在签署合同前详细了解相关条款."
    }
  ];

  return (
    <div className="advance-page">
      {/* Hero Section */}
      <section className="advance-hero">
        <div className="container">
          <h1>{t?.advance?.hero?.title || "资产预付计划"}</h1>
          <p className="hero-subtitle">{t?.advance?.hero?.subtitle || "解决资金瓶颈自由掌控生活"}</p>
          <button onClick={handlePdfClick} className="learn-more-btn">
            {t?.advance?.hero?.button || "了解更多"}
          </button>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="intro-section">
        <div className="container">
          <div className="intro-content">
            <h2>{t?.advance?.intro?.title || "关于资产预付计划"}</h2>
            <p>{t?.advance?.intro?.desc1 || "Homedopt是专为解决资产和现金流问题而诞生的平台"}</p>
            <p>{t?.advance?.intro?.desc2 || "我们的计划旨在帮助您应对有价无市的问题,让您更早获得1/3的资金作为资金周转"}</p>
            <p>{t?.advance?.intro?.desc3 || "我们提供额外的流动资金支持,帮助您应对生活中的其他开支"}</p>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
        <div className="container">
          <h2>{t?.advance?.faq?.title || "常见问题"}</h2>
          <div className="faq-grid">
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <h3>{faq.question}</h3>
                <p>{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Advance; 