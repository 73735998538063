import React from 'react';
import './Asset.css';
import { translations } from '../locales/translations';
import jnerbjn from '../images/jnerbjn.jpg';

function Asset({ language }) {
  const t = translations[language];
  
  console.log('Current language:', language);
  console.log('Translations:', t?.asset);

  const handlePdfClick = () => {
    window.open('/pdf/A4-HOUSE-REVERSION-HOMEDOPT-JUN24.pdf', '_blank');
  };

  return (
    <div className="asset-page">
      {/* Hero Section */}
      <section className="asset-hero">
        <div className="container">
          <h1>{t?.asset?.hero?.title || "资产回馈计划"}</h1>
          <p className="hero-subtitle">{t?.asset?.hero?.subtitle || "以房养老, 让您的房产为您服务！"}</p>
          <button onClick={handlePdfClick} className="learn-more-btn">
            {t?.asset?.hero?.button || "了解更多"}
          </button>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="intro-section">
        <div className="container">
          <div className="intro-wrapper">
            <div className="intro-image">
              <img src={jnerbjn} alt="Asset Return Plan" />
            </div>
            <div className="intro-content">
              <h2>{t?.asset?.intro?.title}</h2>
              <p>{t?.asset?.intro?.desc1}</p>
              <p>{t?.asset?.intro?.desc2}</p>
              <p>{t?.asset?.intro?.desc3}</p>
              <div className="benefits-grid">
                <div className="benefit-card">
                  <h3>{t?.asset?.intro?.benefits?.income?.title}</h3>
                  <p>{t?.asset?.intro?.benefits?.income?.desc}</p>
                </div>
                <div className="benefit-card">
                  <h3>{t?.asset?.intro?.benefits?.ownership?.title}</h3>
                  <p>{t?.asset?.intro?.benefits?.ownership?.desc}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Example Section */}
      <section className="example-section">
        <div className="container">
          <h2>{t?.asset?.example?.title}</h2>
          <div className="example-card">
            <div className="example-item">
              <h3>{t?.asset?.example?.items?.propertyValue?.title}</h3>
              <p className="value">{t?.asset?.example?.items?.propertyValue?.value}</p>
            </div>
            <div className="example-item">
              <h3>{t?.asset?.example?.items?.monthlyReceive?.title}</h3>
              <p className="value">{t?.asset?.example?.items?.monthlyReceive?.value}</p>
            </div>
            <div className="example-item">
              <h3>{t?.asset?.example?.items?.yearlyReceive?.title}</h3>
              <p className="value">{t?.asset?.example?.items?.yearlyReceive?.value}</p>
            </div>
            <div className="example-item">
              <h3>{t?.asset?.example?.items?.total?.title}</h3>
              <p className="value">{t?.asset?.example?.items?.total?.value}</p>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
        <div className="container">
          <h2>{t?.asset?.faq?.title}</h2>
          <div className="faq-grid">
            {t?.asset?.faq?.items.map((faq, index) => (
              <div key={index} className="faq-item">
                <h3>{faq.question}</h3>
                <p>{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Asset; 