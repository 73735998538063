import React, { useState } from 'react';
import './Career.css';
import { translations } from '../locales/translations';

function Career({ language = 'cn' }) {
  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    email: '',
    nationality: '',
    employmentStatus: '',
    fieldOfStudy: '',
    qualification: 'SPM',
    expectedSalary: '',
    reference: '',
    resume: null,
    portfolio: null
  });

  const [expandedSection, setExpandedSection] = useState('');

  const t = translations[language]?.career;

  if (!t) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // 构建邮件内容
    const mailtoSubject = encodeURIComponent('新职位申请 - Admin Assistant');
    const mailtoBody = encodeURIComponent(`
职位申请: Admin Assistant

申请人信息:
姓名: ${formData.fullName}
电话: ${formData.phone}
邮箱: ${formData.email}
国籍: ${formData.nationality}
工作经验: ${formData.employmentStatus === 'experienced' ? '有工作经验' : '应届毕业生'}
学习领域: ${formData.fieldOfStudy}
期望薪资: RM${formData.expectedSalary}
了解渠道: ${formData.reference}

注：简历和作品集请作为附件发送。
    `.trim());

    // 通过邮件发送
    window.location.href = `mailto:career@homedopt.my?subject=${mailtoSubject}&body=${mailtoBody}`;

    // 清空表单
    setFormData({
      fullName: '',
      phone: '',
      email: '',
      nationality: '',
      employmentStatus: '',
      fieldOfStudy: '',
      qualification: 'SPM',
      expectedSalary: '',
      reference: '',
      resume: null,
      portfolio: null
    });

    // 提示用户申请已发送
    alert('请在打开的邮件客户端中添加您的简历和作品集（如有），然后发送邮件。');
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'file' ? files[0] : value
    }));
  };

  const toggleSection = (section) => {
    if (expandedSection === section) {
      setExpandedSection('');
    } else {
      setExpandedSection(section);
    }
  };

  return (
    <div className="career-page">
      <section className="career-hero">
        <h1>{t.hero.title}</h1>
        <p>{t.hero.position}</p>
      </section>

      <section className="job-details">
        <div className="sections-nav">
          <div 
            className={`section-tab ${expandedSection === 'requirements' ? 'active' : ''}`}
            onClick={() => toggleSection('requirements')}
          >
            <h2>{t.sections.requirements.title}</h2>
          </div>
          <div 
            className={`section-tab ${expandedSection === 'responsibilities' ? 'active' : ''}`}
            onClick={() => toggleSection('responsibilities')}
          >
            <h2>{t.sections.responsibilities.title}</h2>
          </div>
          <div 
            className={`section-tab ${expandedSection === 'benefits' ? 'active' : ''}`}
            onClick={() => toggleSection('benefits')}
          >
            <h2>{t.sections.benefits.title}</h2>
          </div>
          <div 
            className={`section-tab ${expandedSection === 'additional-benefits' ? 'active' : ''}`}
            onClick={() => toggleSection('additional-benefits')}
          >
            <h2>{t.sections.additionalBenefits.title}</h2>
          </div>
        </div>

        <div className="section-content-wrapper">
          {expandedSection && (
            <div className="section-content">
              {expandedSection === 'requirements' && (
                <ul>
                  {t.sections.requirements.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              )}
              {expandedSection === 'responsibilities' && (
                <ul>
                  {t.sections.responsibilities.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              )}
              {expandedSection === 'benefits' && (
                <ul>
                  {t.sections.benefits.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              )}
              {expandedSection === 'additional-benefits' && (
                <ul>
                  {t.sections.additionalBenefits.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </section>

      <section className="application-form">
        <h2>{t.form.title}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>{t.form.fullName}</label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>{t.form.phone}</label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>{t.form.email}</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>{t.form.nationality}</label>
            <input
              type="text"
              name="nationality"
              value={formData.nationality}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>{t.form.employmentStatus.label}</label>
            <select name="employmentStatus" onChange={handleChange} required>
              <option value="">{t.form.employmentStatus.options.select}</option>
              <option value="experienced">{t.form.employmentStatus.options.experienced}</option>
              <option value="fresh">{t.form.employmentStatus.options.fresh}</option>
            </select>
          </div>

          <div className="form-group">
            <label>{t.form.fieldOfStudy}</label>
            <input
              type="text"
              name="fieldOfStudy"
              value={formData.fieldOfStudy}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>{t.form.expectedSalary}</label>
            <input
              type="number"
              name="expectedSalary"
              value={formData.expectedSalary}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>{t.form.reference.label}</label>
            <select name="reference" onChange={handleChange} required>
              <option value="">{t.form.reference.options.select}</option>
              <option value="website">{t.form.reference.options.website}</option>
              <option value="social">{t.form.reference.options.social}</option>
              <option value="referral">{t.form.reference.options.referral}</option>
              <option value="other">{t.form.reference.options.other}</option>
            </select>
          </div>

          <div className="form-group">
            <label>{t.form.resume.label}</label>
            <input
              type="file"
              name="resume"
              onChange={handleChange}
              accept=".pdf,.doc,.docx"
            />
            <small className="form-hint">{t.form.resume.hint}</small>
          </div>

          <div className="form-group">
            <label>{t.form.portfolio.label}</label>
            <input
              type="file"
              name="portfolio"
              onChange={handleChange}
              accept=".pdf,.doc,.docx"
            />
            <small className="form-hint">{t.form.portfolio.hint}</small>
          </div>

          <button type="submit" className="submit-button">
            {t.form.submit}
          </button>
        </form>
      </section>
    </div>
  );
}

export default Career; 