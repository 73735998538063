import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';
import { translations } from '../locales/translations';
import logo from '../images/logo.png';
import SuccessModal from './SuccessModal';
import { useUser } from '../context/UserContext';

function Login({ language = 'en' }) {
  const navigate = useNavigate();
  const { updateUser } = useUser();
  const [formData, setFormData] = useState({
    ic: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const t = translations[language]?.login;

  if (!t) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await axios.post('https://homedoptwebsite-1.onrender.com/api/auth/login',
        {
          ic: formData.ic.trim(),
          password: formData.password.trim()
        }
      );

      if (response.data.token) {
        localStorage.setItem('token', response.data.token);
        updateUser(response.data.user);
        setShowSuccessModal(true);
      }
    } catch (err) {
      console.error('Login error details:', err);
      if (err.response?.status === 401) {
        setError(t.errors.invalidCredentials);
      } else if (err.response?.status === 500) {
        setError(t.errors.serverError);
      } else {
        setError(t.errors.loginFailed);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value.trim()
    }));
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    navigate('/profile');
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="login-header">
          <div className="logo-container">
            <img src={logo} alt="HOMEDOPT Logo" className="login-logo" />
          </div>
          <h1>{t.title}</h1>
          <p>{t.subtitle}</p>
        </div>

        {error && <div className="error-message">{error}</div>}

        <form className="login-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>{t.ic}</label>
            <input
              type="text"
              name="ic"
              value={formData.ic}
              onChange={handleChange}
              required
              placeholder={t.icPlaceholder}
            />
          </div>

          <div className="form-group">
            <label>{t.password}</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              placeholder={t.passwordPlaceholder}
            />
          </div>

          <div className="form-options">
            <label className="remember-me">
              <input type="checkbox" />
              <span>{t.rememberMe}</span>
            </label>
            <button
              className="forgot-password-btn"
              onClick={(e) => {
                e.preventDefault();
                // 处理忘记密码逻辑
              }}
            >
              {t.forgotPassword}
            </button>
          </div>

          <button
            type="submit"
            className="login-button"
            disabled={loading}
          >
            {loading ? (language === 'cn' ? '登录中...' : 'Logging in...') : t.loginButton}
          </button>
        </form>
      </div>

      <SuccessModal
        isOpen={showSuccessModal}
        message={language === 'cn' ? '登录成功！' : 'Login successful!'}
        onClose={handleModalClose}
      />
    </div>
  );
}

export default Login; 