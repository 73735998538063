import React, { useState, useEffect, useCallback, useRef } from 'react';
import './Profile.css';
import { translations } from '../locales/translations';
import axios from 'axios';
import { Tree, TreeNode } from 'react-organizational-chart';
import styled from 'styled-components';

const StyledNode = styled.div`
    padding: 1rem;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    background: white;
    border: 2px solid;
    min-width: 200px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-color: ${props => {
        switch (props.role) {
            case 'admin': return '#1a365d';
            case 'boss': return '#d4b106';
            case 'manager': return '#237804';
            case 'agent': return '#096dd9';
            default: return '#d9d9d9';
        }
    }};
`;

const NodeAvatar = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    background: ${props => {
        switch (props.role) {
            case 'admin': return 'linear-gradient(135deg, #1a365d, #2d4a8a)';
            case 'boss': return 'linear-gradient(135deg, #d4b106, #fadb14)';
            case 'manager': return 'linear-gradient(135deg, #237804, #52c41a)';
            case 'agent': return 'linear-gradient(135deg, #096dd9, #1890ff)';
            default: return '#d9d9d9';
        }
    }};
`;

const RoleBadge = styled.span`
    padding: 0.4rem 0.8rem;
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 500;
    color: ${props => props.role === 'boss' ? 'black' : 'white'};
    background: ${props => {
        switch (props.role) {
            case 'admin': return 'linear-gradient(135deg, #1a365d, #2d4a8a)';
            case 'boss': return 'linear-gradient(135deg, #d4b106, #fadb14)';
            case 'manager': return 'linear-gradient(135deg, #237804, #52c41a)';
            case 'agent': return 'linear-gradient(135deg, #096dd9, #1890ff)';
            default: return '#d9d9d9';
        }
    }};
`;

function getRoleText(role, t) {
    if (!t?.roles) {
        return role;
    }

    switch(role) {
        case 'admin': return t.roles.admin;
        case 'boss': return t.roles.boss;
        case 'manager': return t.roles.manager;
        case 'agent': return t.roles.agent;
        default: return role;
    }
}

function CustomNode({ user, t, isExpanded, onToggle }) {
    if (user.isPlaceholder) {
        return (
            <StyledNode 
                role={user.role}
                style={{ 
                    cursor: 'default',
                    opacity: 0.7,
                    minWidth: '100px'
                }}
            >
                <span style={{ textAlign: 'center', width: '100%' }}>
                    {user.name}
                </span>
            </StyledNode>
        );
    }

    return (
        <StyledNode 
            role={user.role} 
            onClick={onToggle}
            className={isExpanded ? 'expanded' : ''}
            style={{ cursor: 'pointer' }}
        >
            <NodeAvatar role={user.role}>
                {user.name.charAt(0).toUpperCase()}
            </NodeAvatar>
            <span>{user.name}</span>
            <RoleBadge role={user.role}>
                {getRoleText(user.role, t)}
            </RoleBadge>
            <span className="expand-indicator">
                {isExpanded ? '▼' : '▶'}
            </span>
        </StyledNode>
    );
}

// 添加修改密码的对话框组件
const PasswordModal = ({ isOpen, onClose, onSubmit, t, error }) => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [localError, setLocalError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setLocalError(t?.passwordModal?.errors?.mismatch || '两次输入的密码不一致');
            return;
        }
        onSubmit(newPassword);
    };

    const displayError = error || localError;

    return isOpen ? (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>{t?.passwordModal?.title || '修改密码'}</h3>
                {displayError && <div className="error-message">{displayError}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>{t?.passwordModal?.newPassword || '新密码'}</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>{t?.passwordModal?.confirmPassword || '确认新密码'}</label>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div className="modal-buttons">
                        <button type="submit" className="submit-button">
                            {t?.passwordModal?.submit || '确认'}
                        </button>
                        <button type="button" onClick={onClose} className="cancel-button">
                            {t?.passwordModal?.cancel || '取消'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    ) : null;
};

function Profile({ language = 'en' }) {
    const user = JSON.parse(localStorage.getItem('user'));
    const t = translations[language]?.profile;
    const [downlines, setDownlines] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [fetchCount, setFetchCount] = useState(0);
    const [expandedNodes, setExpandedNodes] = useState(new Set());
    const [scale, setScale] = useState(0.8);
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
    const treeContainerRef = useRef(null);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [passwordUpdateError, setPasswordUpdateError] = useState('');
    const [isFullscreen, setIsFullscreen] = useState(false);

    // 使用 useCallback 包装 getAllUserIds 函数
    const getAllUserIds = useCallback((users) => {
        const ids = new Set();
        users.forEach(user => {
            ids.add(user.user.id);
            if (user.children) {
                const childIds = getAllUserIds(user.children);
                childIds.forEach(id => ids.add(id));
            }
        });
        return ids;
    }, []);

    // 在 useEffect 中使用
    useEffect(() => {
        if (downlines.length > 0) {
            const allIds = getAllUserIds(downlines);
            allIds.add(user.id);
            setExpandedNodes(allIds);
        }
    }, [downlines, user?.id, getAllUserIds]);

    const fetchDownlines = useCallback(async () => {
        if (fetchCount > 0) return;

        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(
                `https://homedoptwebsite-1.onrender.com/api/users/${user.id}/all-downlines`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            setDownlines(response.data.downlines);
            setFetchCount(prev => prev + 1);
        } catch (err) {
            console.error('Error fetching downlines:', err);
            setError(t?.errors?.fetchError || '获取下线数据失败');
        } finally {
            setLoading(false);
        }
    }, [user?.id, t?.errors?.fetchError, fetchCount]);

    useEffect(() => {
        if (user && fetchCount === 0) {
            fetchDownlines();
        }
    }, [user, fetchDownlines, fetchCount]);

    // 修改 buildTree 函数
    const buildTree = (downlines) => {
        // 创建一个映射来存储所有节点
        const nodeMap = new Map();
        let totalNodes = 1; // 从1开始计数，因为包括当前用户

        // 首先创建所有节点的映射
        downlines.forEach(downline => {
            nodeMap.set(downline.user.id, {
                ...downline,
                children: []
            });
        });

        // 找到当前用户的直接下线
        const directDownlines = downlines.filter(downline => 
            downline.user.uplineId === user.id
        );

        // 递归构建树结构，但限制节点数量
        const buildSubTree = (node) => {
            if (totalNodes >= 30) {  // 修改为30个节点限制
                return null;
            }

            const directChildren = downlines.filter(d => 
                d.user.uplineId === node.user.id
            );

            node.children = directChildren.map(child => {
                if (totalNodes >= 30) {
                    return null;
                }
                totalNodes++;
                const childNode = nodeMap.get(child.user.id);
                return buildSubTree(childNode);
            }).filter(Boolean);

            return node;
        };

        // 构建树，限制直接下线的数量
        const rootNodes = directDownlines
            .slice(0, Math.min(directDownlines.length, 29)) // 最多显示29个直接下线（加上当前用户共30个）
            .map(downline => {
                totalNodes++;
                const node = nodeMap.get(downline.user.id);
                return buildSubTree(node);
            })
            .filter(Boolean);

        // 如果超过限制，添加提示信息
        if (directDownlines.length > 29) {
            rootNodes.push({
                user: {
                    id: 'more',
                    name: '...',
                    role: 'agent',
                    isPlaceholder: true
                },
                children: []
            });
        }

        return rootNodes;
    };

    // 修改初始化展开状态，默认只展开当前用户节点
    useEffect(() => {
        if (downlines.length > 0) {
            setExpandedNodes(new Set([user.id]));
        }
    }, [downlines, user?.id]);

    // 修改节点展开/收起逻辑
    const toggleNode = (nodeId) => {
        setExpandedNodes(prev => {
            const newSet = new Set(prev);
            if (newSet.has(nodeId)) {
                // 收起时，同时收起所有子节点
                const nodeToCollapse = findNodeById(nodeId, buildTree(downlines));
                if (nodeToCollapse) {
                    getAllChildIds(nodeToCollapse).forEach(id => newSet.delete(id));
                }
                newSet.delete(nodeId);
            } else {
                // 展开时只展开当前点击的节点
                newSet.add(nodeId);
            }
            return newSet;
        });
    };

    // 添加辅助函数来查找节点
    const findNodeById = (id, nodes) => {
        for (const node of nodes) {
            if (node.user.id === id) {
                return node;
            }
            if (node.children) {
                const found = findNodeById(id, node.children);
                if (found) return found;
            }
        }
        return null;
    };

    // 添加辅助函数来获取所有子节点ID
    const getAllChildIds = (node) => {
        const ids = [];
        if (node.children) {
            node.children.forEach(child => {
                ids.push(child.user.id);
                ids.push(...getAllChildIds(child));
            });
        }
        return ids;
    };

    // 修改树节点渲染逻辑
    const renderTree = (nodeData) => {
        const isExpanded = expandedNodes.has(nodeData.user.id);
        
        return (
            <TreeNode 
                label={
                    <CustomNode 
                        user={nodeData.user} 
                        t={t} 
                        isExpanded={isExpanded}
                        onToggle={() => toggleNode(nodeData.user.id)}
                    />
                }
            >
                {isExpanded && nodeData.children?.map((child, index) => (
                    <React.Fragment key={index}>
                        {renderTree(child)}
                    </React.Fragment>
                ))}
            </TreeNode>
        );
    };

    // 修改缩放控制函数
    const handleZoom = (delta) => {
        setScale(prevScale => {
            const newScale = prevScale + delta;
            return Math.min(Math.max(0.2, newScale), 2); // 允许更小的缩放比例
        });
    };

    // 修改缩放步长
    const handleZoomButton = (type) => {
        switch(type) {
            case 'in':
                handleZoom(0.3); // 更大的放大步长
                break;
            case 'out':
                handleZoom(-0.3); // 更大的缩小步长
                break;
            case 'reset':
                setScale(isFullscreen ? 0.6 : 0.8); // 根据是否全屏设置不同的默认缩放
                setPosition({ x: 0, y: 0 });
                break;
            default:
                break;
        }
    };

    // 添加拖动控制函数
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartPosition({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - startPosition.x,
                y: e.clientY - startPosition.y
            });
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    // 在组件卸载时移除事件监听
    useEffect(() => {
        const handleWheel = (e) => {
            if (e.ctrlKey) {
                e.preventDefault();
                const delta = e.deltaY > 0 ? -0.1 : 0.1;
                handleZoom(delta);
            }
        };

        const container = treeContainerRef.current;
        if (container) {
            container.addEventListener('wheel', handleWheel, { passive: false });
        }

        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheel);
            }
        };
    }, []);

    // 添加修改密码的处理函数
    const handlePasswordChange = async (newPassword) => {
        try {
            const token = localStorage.getItem('token');
            console.log('Sending password update request for user:', user.id); // 添加日志
            
            const response = await axios.put(
                `https://homedoptwebsite-1.onrender.com/api/users/${user.id}/password`,
                { newPassword },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.message) {
                setIsPasswordModalOpen(false);
                alert(t?.passwordModal?.success || '密码修改成功');
            }
        } catch (err) {
            console.error('Password update error:', err); // 添加错误日志
            setPasswordUpdateError(
                err.response?.data?.message || 
                t?.passwordModal?.errors?.updateFailed || 
                '密码修改失败'
            );
        }
    };

    // 添加全屏切换函数
    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
        // 重置位置和缩放
        setPosition({ x: 0, y: 0 });
        setScale(0.6); // 全屏时默认更小的缩放比例
    };

    if (!user) {
        return null;
    }

    return (
        <div className="profile-page">
            <div className="profile-container">
                <div className="profile-header">
                    <h1>{t?.title || "个人中心"}</h1>
                </div>

                <div className="profile-content">
                    <div className="profile-card">
                        <div className="profile-avatar" data-role={user.role}>
                            {user.name.charAt(0).toUpperCase()}
                        </div>
                        <div className="profile-info">
                            <div className="info-item">
                                <label>{t?.labels?.name || "用户名"}</label>
                                <span>{user.name}</span>
                            </div>
                            <div className="info-item">
                                <label>{t?.labels?.role || "角色"}</label>
                                <span className="role-text" data-role={user.role}>
                                    {getRoleText(user.role, t)}
                                </span>
                            </div>
                        </div>
                        <div className="profile-actions">
                            <button 
                                className="change-password-button"
                                onClick={() => setIsPasswordModalOpen(true)}
                            >
                                {t?.buttons?.changePassword || "修改密码"}
                            </button>
                        </div>
                    </div>

                    <div className="downline-tree-section">
                        <h2>{t?.downlines?.title || "团队成员"}</h2>
                        {!isFullscreen && (
                            <div className="tree-controls">
                                <button onClick={() => handleZoomButton('in')}>
                                    <span className="zoom-icon">+</span>
                                </button>
                                <button onClick={() => handleZoomButton('out')}>
                                    <span className="zoom-icon">-</span>
                                </button>
                                <button onClick={toggleFullscreen}>
                                    <span className="zoom-icon">⛶</span>
                                </button>
                            </div>
                        )}
                        {loading ? (
                            <div className="loading">{t?.downlines?.loading || "加载中..."}</div>
                        ) : error ? (
                            <div className="error-message">{error}</div>
                        ) : (
                            <div 
                                className={`tree-container ${isFullscreen ? 'fullscreen' : ''}`}
                                ref={treeContainerRef}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseUp}
                            >
                                {isFullscreen && (
                                    <div className="fullscreen-controls">
                                        <button onClick={() => handleZoomButton('in')}>
                                            <span className="zoom-icon">+</span>
                                        </button>
                                        <button onClick={() => handleZoomButton('out')}>
                                            <span className="zoom-icon">-</span>
                                        </button>
                                        <button onClick={toggleFullscreen} className="exit-fullscreen">
                                            <span className="zoom-icon">⮌</span>
                                        </button>
                                    </div>
                                )}
                                <div 
                                    className="tree-content"
                                    style={{
                                        transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
                                        transformOrigin: '0 0'
                                    }}
                                >
                                    <Tree
                                        lineWidth={'2px'}
                                        lineColor={'#66BD40'}
                                        lineBorderRadius={'10px'}
                                        label={
                                            <CustomNode 
                                                user={user} 
                                                t={t}
                                                isExpanded={expandedNodes.has(user.id)}
                                                onToggle={() => toggleNode(user.id)}
                                            />
                                        }
                                    >
                                        {expandedNodes.has(user.id) && buildTree(downlines).map((node, index) => (
                                            <React.Fragment key={index}>
                                                {renderTree(node)}
                                            </React.Fragment>
                                        ))}
                                    </Tree>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <PasswordModal
                isOpen={isPasswordModalOpen}
                onClose={() => {
                    setIsPasswordModalOpen(false);
                    setPasswordUpdateError('');
                }}
                onSubmit={handlePasswordChange}
                t={t}
                error={passwordUpdateError}
            />
        </div>
    );
}

export default Profile; 