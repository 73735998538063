import React, { useState } from 'react';
import './Contact.css';
import contactImage from '../images/contact.jpg';
import { translations } from '../locales/translations';

function Contact({ language = 'cn' }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const t = translations[language]?.contact;

  if (!t) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // 构建WhatsApp消息内容
    const whatsappMessage = `
*新咨询请求*
姓名: ${formData.name}
电话: ${formData.phone}
邮箱: ${formData.email}
主题: ${formData.subject}
消息: ${formData.message}
    `.trim();

    // 直接通过WhatsApp发送
    const whatsappLink = `https://wa.me/60172013168?text=${encodeURIComponent(whatsappMessage)}`;
    window.open(whatsappLink, '_blank');

    // 清空表单
    setFormData({
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/60172013168', '_blank');
  };

  return (
    <div className="contact-page">
      {/* 英雄区域 */}
      <section className="contact-hero">
        <div className="hero-content">
          <h1>{t.hero.title}</h1>
          <p>{t.hero.subtitle}</p>
        </div>
      </section>

      <div className="contact-container">
        {/* 联系信息卡片 */}
        <section className="contact-info-section">
          <div className="info-card">
            <div className="info-icon">📍</div>
            <h3>{t.info.office.title}</h3>
            <p>{t.info.office.address}</p>
          </div>

          <div className="info-card clickable" onClick={handleWhatsAppClick}>
            <div className="info-icon">📞</div>
            <h3>{t.info.phone.title}</h3>
            <p>{t.info.phone.number}</p>
            <div className="whatsapp-button">
              <svg className="whatsapp-icon" viewBox="0 0 24 24">
                <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"/>
              </svg>
              <span>{t.info.phone.whatsapp}</span>
            </div>
          </div>

          <div className="info-card">
            <div className="info-icon">✉️</div>
            <h3>{t.info.email.title}</h3>
            <p>{t.info.email.address}</p>
          </div>
        </section>

        {/* 联系表单区域 */}
        <section className="contact-form-section">
          <div className="form-container">
            <h2>{t.form.title}</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder={t.form.name}
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-row">
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    placeholder={t.form.email}
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    name="phone"
                    placeholder={t.form.phone}
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="subject"
                  placeholder={t.form.subject}
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  placeholder={t.form.message}
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows="5"
                ></textarea>
              </div>
              <button type="submit" className="submit-button">{t.form.submit}</button>
            </form>
          </div>
          <div className="form-image">
            <img src={contactImage} alt="Contact" />
          </div>
        </section>

        {/* 地图区域 */}
        <section className="map-section">
          <div className="map-container">
            <div className="map-header">
              <div className="location-icon">📍</div>
              <h3>{t.map.title}</h3>
            </div>
            <iframe
              title="HOMEDOPT Office Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.5392949657244!2d101.70893121475872!3d2.9523095978356193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdcb839c50b619%3A0x3c24a9564980a18d!2s2PG9%2B95%20Serdang%2C%20Selangor!5e0!3m2!1sen!2smy!4v1647856723015!5m2!1sen!2smy&output=embed&disableDefaultUI=1&zoom=18"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>

        {/* 悬浮WhatsApp按钮 */}
        <button className="floating-whatsapp" onClick={handleWhatsAppClick}>
          <svg className="whatsapp-icon" viewBox="0 0 24 24">
            <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z"/>
          </svg>
          <span>{t.info.phone.whatsapp}</span>
        </button>
      </div>
    </div>
  );
}

export default Contact; 