import './App.css';
import logo from './images/logo.png';
import { useState, useEffect } from 'react';
import { translations } from './locales/translations';
import { Routes, Route, Link, useLocation, useNavigate, Navigate } from 'react-router-dom';
import About from './components/About';
import Home from './components/Home';
import Contact from './components/Contact';
import Career from './components/Career';
import FAQ from './components/FAQ';
import Adoption from './components/Adoption';
import Asset from './components/Asset';
import Mortgage from './components/Mortgage';
import Advance from './components/Advance';
import Rental from './components/Rental';
import Login from './components/Login';
import Profile from './components/Profile';
import { UserProvider, useUser } from './context/UserContext';
import UserManagement from './components/UserManagement';

function AppContent() {
  const [language, setLanguage] = useState('en');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user, updateUser } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('preferred-language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
    } else {
      localStorage.setItem('preferred-language', 'en');
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const t = translations[language];

  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    localStorage.setItem('preferred-language', lang);
  };

  const isFAQPage = location.pathname === '/faq';

  const handleConsultClick = () => {
    navigate('/contact');
    setIsMobileMenuOpen(false);
  };

  const handleLoginClick = () => {
    navigate('/login');
    setIsMobileMenuOpen(false);
  };

  const handleLogout = () => {
    updateUser(null);
    navigate('/');
    setIsMobileMenuOpen(false);
  };

  const handleProfileClick = () => {
    navigate('/profile');
    setIsMobileMenuOpen(false);
  };

  if (!t) return null;

  return (
    <div className="App">
      <nav className="nav-bar">
        <div className="nav-container">
          <div className="nav-left">
            <Link to="/" className="logo" onClick={handleLinkClick}>
              <img src={logo} alt="Homedopt Logo" className="logo-image" />
              <div className="logo-text">
                <span className="logo-text-blue">Home</span>
                <span className="logo-text-green">dopt</span>
              </div>
            </Link>
          </div>
          <button
            className={`mobile-menu-button ${isMobileMenuOpen ? 'active' : ''}`}
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            <span className="menu-icon"></span>
          </button>
          <div className={`nav-right ${isMobileMenuOpen ? 'active' : ''}`}>
            <div className="nav-links">
              <Link to="/about" className="nav-link" onClick={handleLinkClick}>{t?.nav?.about}</Link>
              <div className="dropdown">
                <button className="nav-link dropdown-toggle">
                  {t?.nav?.services}
                  <span className="dropdown-icon">▼</span>
                </button>
                <div className="dropdown-menu">
                  <Link to="/homeadoption" className="dropdown-item" onClick={handleLinkClick}>{t?.serviceMenu?.adoption}</Link>
                  <Link to="/housereversion" className="dropdown-item" onClick={handleLinkClick}>{t?.serviceMenu?.asset}</Link>
                  <Link to="/mortgagerelief" className="dropdown-item" onClick={handleLinkClick}>{t?.serviceMenu?.mortgage}</Link>
                  <Link to="/assetprepayment" className="dropdown-item" onClick={handleLinkClick}>{t?.serviceMenu?.advance}</Link>
                  <Link to="/rental" className="dropdown-item" onClick={handleLinkClick}>{t?.serviceMenu?.rental}</Link>
                </div>
              </div>
              <Link to="/contact" className="nav-link" onClick={handleLinkClick}>{t?.nav?.contact}</Link>
              <Link to="/career" className="nav-link" onClick={handleLinkClick}>{t?.nav?.career}</Link>
              <Link to="/faq" className="nav-link" onClick={handleLinkClick}>{t?.nav?.faq}</Link>
            </div>
            <div className="nav-buttons">
              <button
                className="nav-button primary"
                onClick={handleConsultClick}
              >
                {t?.nav?.consult}
              </button>
              {user ? (
                <div className="user-menu">
                  <button
                    className="nav-button secondary user-button"
                    onClick={handleProfileClick}
                  >
                    {user.name}
                    <span className="dropdown-icon">▼</span>
                  </button>
                  <div className="user-dropdown">
                    <button
                      className="profile-button"
                      onClick={handleProfileClick}
                    >
                      {t?.nav?.profile || "个人中心"}
                    </button>
                    {user.role === 'admin' && (
                      <button
                        className="profile-button"
                        onClick={() => {
                          navigate('/user-management');
                          setIsMobileMenuOpen(false);
                        }}
                      >
                        {t?.nav?.userManagement || "用户管理"}
                      </button>
                    )}
                    <button
                      className="logout-button"
                      onClick={handleLogout}
                    >
                      {t?.nav?.logout || "退出登录"}
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  className="nav-button secondary"
                  onClick={handleLoginClick}
                >
                  {t?.nav?.login}
                </button>
              )}
              <div className="language-dropdown">
                <button className="language-btn">
                  <svg className="language-icon" viewBox="0 0 24 24">
                    <path d="M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z" />
                  </svg>
                </button>
                <div className="language-menu">
                  <button
                    className={`language-option ${language === 'cn' ? 'active' : ''}`}
                    onClick={() => handleLanguageChange('cn')}
                  >
                    中文
                  </button>
                  <button
                    className={`language-option ${language === 'en' ? 'active' : ''}`}
                    onClick={() => handleLanguageChange('en')}
                  >
                    English
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      {isMobileMenuOpen && (
        <div
          className="mobile-menu-overlay"
          onClick={() => setIsMobileMenuOpen(false)}
        />
      )}

      <Routes>
        <Route path="/" element={<Home language={language} />} />
        <Route path="/about" element={<About language={language} />} />
        <Route path="/contact" element={<Contact language={language} />} />
        <Route path="/career" element={<Career language={language} />} />
        <Route path="/faq" element={<FAQ language={language} />} />
        <Route path="/homeadoption" element={<Adoption language={language} />} />
        <Route path="/housereversion" element={<Asset language={language} />} />
        <Route path="/mortgagerelief" element={<Mortgage language={language} />} />
        <Route path="/assetprepayment" element={<Advance language={language} />} />
        <Route path="/rental" element={<Rental language={language} />} />
        <Route path="/login" element={<Login language={language} onLoginSuccess={updateUser} />} />
        <Route path="/profile" element={<Profile language={language} />} />
        <Route
          path="/user-management"
          element={
            user?.role === 'admin' ? (
              <UserManagement language={language} />
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      </Routes>

      {!isFAQPage && (
        <footer className="footer">
          <div className="footer-container">
            <div className="footer-intro">
              <div className="footer-brand">
                <img src={logo} alt="Homedopt Logo" className="footer-logo" />
                <div className="footer-logo-text">
                  <span className="logo-text-blue">Home</span>
                  <span className="logo-text-green">dopt</span>
                </div>
              </div>
              <p className="footer-description">
                {t?.footer?.description}
              </p>
            </div>

            <div className="footer-offices">
              <div className="footer-office">
                <h3>{t?.footer?.offices?.headquarters}</h3>
                <p className="company-name">Homedopt SDN BHD (1505014-M)</p>
                <p className="address">G-27, G Floor, The Heritage, Jalan SB Dagang, Mines Wellness City, 43300 Seri Kembangan, Selangor.</p>
              </div>

              <div className="footer-office">
                <h3>{t?.footer?.offices?.penang}</h3>
                <p className="company-name">Homedopt SDN BHD (1505014-M)</p>
                <p className="address">G-3-3, Lorong Bayan Indah 1, Bay Avenue, 11900 Bayan Lepas, Penang</p>
              </div>

              <div className="footer-office">
                <h3>{t?.footer?.offices?.johor}</h3>
                <p className="company-name">Homedopt SDN BHD (1505014-M)</p>
                <p className="address">No. 23A.06B, Level 23A, Johor Bahru City Square Office Tower, 106-108, Jalan Wong Ah Fook, 80000 Johor Bahru, Johor.</p>
              </div>
            </div>

            <div className="footer-contact">
              <div className="contact-info">
                <p><span className="icon">📞</span>+60172013168</p>
                <p><span className="icon">✉️</span>liewsylvia216@gmail.com</p>
              </div>
            </div>

            <div className="social-buttons">
              <a href="https://web.facebook.com/profile.php?id=61567491904142" target="_blank" rel="noopener noreferrer" className="social-link facebook">
                <svg className="facebook-icon" viewBox="0 0 24 24">
                  <path d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z" />
                </svg>
                <span>Facebook</span>
              </a>
              <a href="https://wa.me/60172013168" target="_blank" rel="noopener noreferrer" className="social-link whatsapp">
                <svg className="whatsapp-icon" viewBox="0 0 24 24">
                  <path d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413z" />
                </svg>
                <span>WhatsApp</span>
              </a>
            </div>

            <div className="footer-bottom">
              <p>© 2024 Homedopt SDN BHD | All Rights Reserved</p>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
}

function App() {
  return (
    <UserProvider>
      <AppContent />
    </UserProvider>
  );
}

export default App;
