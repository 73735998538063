import React from 'react';
import './Mortgage.css';
import { translations } from '../locales/translations';
import mortgageImg from '../images/mortgage.jpg';

function Mortgage({ language }) {
  const t = translations[language];
  
  // 添加调试日志
  console.log('Current language:', language);
  console.log('Translations:', t?.mortgage);

  const handlePdfClick = () => {
    window.open('/pdf/A4-MORTGAGE-RELIEF-HOMEDOPT.pdf', '_blank');
  };

  return (
    <div className="mortgage-page">
      {/* Hero Section */}
      <section className="mortgage-hero">
        <div className="container">
          <h1>{t?.mortgage?.hero?.title || "房贷压力的解决方案"}</h1>
          <p className="hero-subtitle">{t?.mortgage?.hero?.subtitle || "我们提供5年免除房贷压力的解决方案！"}</p>
          <p className="hero-desc">{t?.mortgage?.hero?.desc || "让您在未来的五年里,无需为房贷支付而烦恼"}</p>
          <button onClick={handlePdfClick} className="learn-more-btn">
            {t?.mortgage?.hero?.button || "了解更多"}
          </button>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="intro-section">
        <div className="container">
          <div className="intro-wrapper">
            <div className="intro-image">
              <img src={mortgageImg} alt="Mortgage Relief Solution" />
            </div>
            <div className="intro-content">
              <h2>{t?.mortgage?.intro?.title || "经济不景 房贷压力？"}</h2>
              <p className="intro-highlight">{t?.mortgage?.intro?.highlight || "高达5年 免房贷"}</p>
              <p>{t?.mortgage?.intro?.desc1 || "Homedopt是专为解决资产和现金流问题而诞生的平台"}</p>
              <p>{t?.mortgage?.intro?.desc2 || "我们的计划旨在帮助您应对房贷压力这种挑战, 让您在一段时间内免除房贷压力, 以减轻您的经济负担"}</p>
              <p>{t?.mortgage?.intro?.desc3 || "我们提供额外的流动资金支持,帮助您应对生活中的其他开支"}</p>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
        <div className="container">
          <h2>{t?.mortgage?.faq?.title || "常见问题"}</h2>
          <div className="faq-grid">
            {(t?.mortgage?.faq?.items || []).map((faq, index) => (
              <div key={index} className="faq-item">
                <h3>{faq.question}</h3>
                <p>{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Mortgage; 